import {useEffect, useState} from "react";
import {Col, Row} from "react-bootstrap";

const AddressView = (props) => {
    const [address, setAddress] = useState(props.address);

    useEffect(() => {
        setAddress(props.address);
    }, [props.address]);

    const generateAddressField = (addressField) => addressField && addressField + ', ';

    return (
        <>
            <Row>
                <Col>
                    {address && address.streetAddress}
                </Col>
            </Row>
            <Row>
                <Col>
                    {generateAddressField(address && address.cityNm)}
                    {generateAddressField(address && address.stateCd)}
                    {address && address.postalCd}
                </Col>
            </Row>
        </>
    )
}

AddressView.defaultProps = {
    initialAddress: {
        streetAddress: "",
        cityNm: "",
        stateCd: "",
        postalCd: ""
    }
}

export default AddressView;