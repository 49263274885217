import React, {useState, Fragment, useEffect} from 'react';
import Button from 'react-bootstrap/Button'
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form"
import InputGroup from "react-bootstrap/InputGroup";
import AxiosInstance from '../../AxiosInstance.js'
import {useNavigate} from "react-router";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Logger} from "../../Utils";


const LinkAccount = (props) => {

    const [valid, setValid] = useState(false);
    const [success,setSuccess] = useState(false);
    const [alert, setAlert] = useState({"variant": "primary", "message": ""});
    const [showAlert, setShowAlert] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [formData, setFormData] = useState({
        "orgNbr": "",
        "lastNm": "",
        "phone": "",
        "birthDt": ""
    });
    const navigate = useNavigate();

    AxiosInstance.defaults.headers['Authorization-Token'] = localStorage.getItem('userToken');

    useEffect(()=>{
        Logger({page: 'link-account', action: 'open'})

    },[])

    const handleSubmit = e => {


        e.preventDefault();
        e.stopPropagation();

        Logger({
            page: 'link-account',
            method: "handleSubmit",
            payload: {type: "formData", data: formData}
        })

        let errors = {};

        // !/([A-Z0-9]){4,}$/i.test(formData.orgNbr) ? errors.orgNbr = "Invalid Org number" :
        //     delete errors.orgNbr;

        !/([A-Z0-9]){3,}$/i.test(formData.lastNm) ? errors.lastNm = "Invalid Last Name" :
            delete errors.lastNm;

        !/(\d+){4,}$/i.test(formData.phone) ? errors.phone = "Invalid Phone Number" :
            delete errors.phone;

        !/^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/.test(formData.birthDt) ? errors.birthDt = "Invalid Date of Birth" :
            delete errors.birthDt;

        setFormErrors({...errors})

        if (Object.keys(errors).length === 0) {
            let linkData = {
                "email" : localStorage.getItem("email"),
                // "orgNbr": formData.orgNbr,
                "lastNm": formData.lastNm.trim(),
                "birthDt": formData.birthDt,
                "phone": formData.phone.substr(10)
            }

            AxiosInstance.post('/api/v1/member/link', linkData)
                .then(res => {

                    let {data} = res;

                    if (data !== undefined && data !== null) {
                        console.log("data: " + JSON.stringify(data));
                        setValid(true);

                        localStorage.setItem("memberId", data);
                        // '/api/v1/member/:member_id'


                        AxiosInstance.post('/api/v1/identity', {"_id": localStorage.getItem("id"), "memberId": data});

                        AxiosInstance.get(`/api/v1/member/${data}`).then((res)=> {
                            localStorage.setItem('memberData',  JSON.stringify(res.data));
                        });

                        setAlert({variant: "primary", message: ""})
                        setShowAlert(true);
                        setSuccess(true);
                    } else {
                        setAlert({variant: "danger", message: "Cannot link your account. Please verify your information and try again."});
                        setShowAlert(true);
                    }


                }).catch((err) => {
                    setAlert({variant: "danger", message: "Cannot link your account. Please verify your information and try again."});
                    setShowAlert(true);

            })
        }


    }

    const handleContinueClick =()=>{
        navigate('/my/overview');
    }

    const handleDateInputChange = (event) => {
        const target = event.target;
        const value = target.value.replace(/[^0-9]/g, '');
        const name = target.name;

        let temp_val = value;

        if (value.length > 4) {
            temp_val = temp_val.substr(0, 2) + '/' +
                temp_val.substr(2, 2) + '/' +
                temp_val.substr(4)
        } else if (value.length > 2) {
            temp_val = temp_val.substr(0, 2) + '/' +
                temp_val.substr(2)
        }
        ;

        setFormData({
            ...formData,
            [name]: temp_val
        });
        target.value = temp_val;

        //reset error message if exists
        let errors = formErrors;
        delete errors[name];
        setFormErrors({...errors});
    }

    const handlePhoneInputChange = (event) => {
        const target = event.target;
        const value = target.value.replace(/[^0-9]/g, '');
        const name = target.name;

        let temp_val = value;

        if (value.length > 0) {
            temp_val = "(###) ###-" + temp_val.substring(0, 4);
        }
        setFormData({
            ...formData,
            [name]: temp_val
        });

        target.value = temp_val;

        //reset error message if exists
        let errors = formErrors;
        delete errors[name];
        setFormErrors({...errors});

    }

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setFormData({
            ...formData,
            [name]: value
        });

        //reset error message if exists
        let errors = formErrors;
        delete errors[name];
        setFormErrors({...errors});
    }

    let linkAccountLayout =
        <div className="container">
            <div className="row justify-content-md-center mt-5">
                <div className="col-md-5 text-center">
                    <div className="h4 mb-1">Link Account</div>

                    <div className="text-muted small">(all fields are required)</div>
                </div>

            </div>

            <div className="row justify-content-md-center mt-2 pb-5 ">
                <Form className="col-md-4 col-sm-10 d-grid gap-2" autoComplete="disabled" noValidate onSubmit={handleSubmit}>
                    <Alert variant={alert.variant} show={showAlert}>
                        {alert.message}
                    </Alert>
                    {/*<InputGroup className="mt-2">*/}
                    {/*    <Form.Control name="orgNbr" required type="input"*/}
                    {/*                  onChange={handleInputChange}*/}
                    {/*                  isInvalid={formErrors.orgNbr}*/}
                    {/*                  isValid={valid}*/}
                    {/*                  placeholder="Organization Number"*/}
                    {/*                  autoComplete="disabled"/>*/}
                    {/*    <Form.Control.Feedback type="invalid">{formErrors.orgNbr}</Form.Control.Feedback>*/}
                    {/*</InputGroup>*/}


                    <Row>
                        <Col>
                            <div className="text-secondary">Last Name</div>
                            <Form.Control name="lastNm" required type="input"
                                          onChange={handleInputChange}
                                          isInvalid={formErrors.lastNm}
                                          isValid={valid}
                                          placeholder="Last Name"
                                          autoComplete="disabled"/>
                            <Form.Control.Feedback type="invalid">{formErrors.lastNm}</Form.Control.Feedback>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="text-secondary">Birth Date</div>
                            <Form.Control name="birthDt" required type="input"
                                          maxLength="10"
                                          isInvalid={formErrors.birthDt}
                                          isValid={valid}
                                          onChange={handleDateInputChange}
                                          placeholder="Birth Date"
                                          autoComplete="disabled"/>
                            <Form.Control.Feedback type="invalid">{formErrors.birthDt}</Form.Control.Feedback>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <div className="text-secondary">Phone Number (last 4)</div>
                            <Form.Control name="phone" required type="input"
                                          onChange={handlePhoneInputChange}
                                          isInvalid={formErrors.phone}
                                          isValid={valid}
                                          placeholder="Last 4 of phone number"
                                          autoComplete="disabled"/>
                            <Form.Control.Feedback type="invalid">{formErrors.phone}</Form.Control.Feedback>
                        </Col>
                    </Row>
                    <div className="mb-2 text-secondary small">
                        <i className="bi bi-info-circle-fill" style={{color:"#d95011"}}/>{` `}
                        You can ONLY link an existing account.
                        Please get in touch with your organization's admins if
                        you need help creating and/or linking an account.
                    </div>
                    <Row>
                       <Col className="col-12">
                           <Button variant='default' className="d-block m-auto" type="submit">
                               Link My Account
                           </Button>
                       </Col>
                    </Row>


                </Form>

            </div>
        </div>

    let successLayout =
        <div className="container">
            <div className="row justify-content-md-center mt-5">
                <div className="col-md-5 text-center">
                    <div className="h4 mb-1">Link Account</div>

                </div>

            </div>

            <div className="row  mt-2">
                <div className="m-auto text-center col-md-5 col-sm-10 " >
                    <div>
                        Your account has been linked successfully, please tap "Continue" for you Account Summary
                    </div>

                    <Button variant='default'
                            className="mt-3"
                            type="submit"
                            onClick={handleContinueClick}>
                        Continue
                    </Button>

                </div>

            </div>
        </div>



    return (
        success? successLayout: linkAccountLayout
    );

}

export default LinkAccount;