import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router";
import AxiosInstance from "../../AxiosInstance";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import {Link} from "react-router-dom";
import {Dropdown} from "react-bootstrap";
import {AgeCalculator, CustomToggle} from "../../Utils";
import HouseholdAddExisting from "../Household/HouseholdAddExisting";
import HouseholdLink from "../Household/HouseholdLink";


const MemberHousehold = (props) => {


    const [household, setHousehold] = useState({});
    const [showAddExisting, setShowAddExisting] = useState(false);
    const [showLinkHousehold, setShowLinkHousehold] = useState(false);
    // const [formErrors, setFormErrors] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
            if (props.id != null) {
                getHouseholdSorted().then(household => {
                    setHousehold(household);
                });
            }
        }, [props.id]
    );

    const getHouseholdSorted = async () => {

        let householdRes  = await AxiosInstance.get(`/api/v1/member/${props.id}/household`);

        if (householdRes.data.members !== undefined && householdRes.members !== null) {
            householdRes.data.members.sort((a,b) => {

                if (a.memberId == householdRes.data.primaryMemberId) {
                    return -1;
                }

                if (a.isActive && !b.isActive) {
                    return -1;
                }

                if (a.age > b.age && a.isActive == true) {
                    return -1;
                } else {
                    return 0;
                }

            } );
        }

        return householdRes.data;
    }


    const handleMemberRemove = (remove_member_id) => {


        AxiosInstance.get(`/api/v1/member/${remove_member_id}/household/${household._id}/remove`).then((res => {
            getHouseholdSorted().then(household => {
                setHousehold(household);
            });
        }));
    }

    const handleHouseholdExistingMemberAdded = () => {
        setShowAddExisting(false);

        if (props.id != null) {
            getHouseholdSorted().then(household => {
                setHousehold(household);
            });
        }
    }

    const handleMakePrimary = (primary_member_id) => {

        const memberHouseholdPayload = {
            member_id: primary_member_id,
            household_id: household._id,
            isPrimary: true
        }

        AxiosInstance.post(`/api/v1/member/${primary_member_id}/household/add`, memberHouseholdPayload).then((res => {
            if (props.id != null) {
                getHouseholdSorted().then(household => {
                    setHousehold(household);
                });
            }
        }));
    }


    const handleMakeActive = (active_member_id, isActive) => {

        const memberHouseholdPayload = {
            member_id: active_member_id,
            household_id: household._id,
            isActive: isActive
        }

        AxiosInstance.post(`/api/v1/member/${active_member_id}/household/add`, memberHouseholdPayload).then((res => {
            if (props.id != null) {
                getHouseholdSorted().then(household => {
                    setHousehold(household);
                });
            }
        }));
    }


    return (
        <div className="mt-3 col-lg-8  col-md-10 col-sm-10 pb-5">
            <HouseholdAddExisting household_id={household._id}
                                  onAdded={handleHouseholdExistingMemberAdded}
                                  onClosed={() => setShowAddExisting(!showAddExisting)}
                                  show={showAddExisting}
            />
            <Row className="fw-bold text-secondary d-sm-flex d-none">
                <Col></Col>
                <Col className="col-1">Age</Col>
                <Col className="col-8 col-md-4 col-lg-4">Relationship</Col>
            </Row>
            {
                household.members ? household.members.map((householdMember, idx) => (
                    <Row key={idx}
                         className={idx % 2 === 0 ? "grid-row-alternative" : null}
                         style={{
                             fontStyle: !householdMember.isActive ? "italic" : null,
                             textDecoration: !householdMember.isActive ? "line-through" : "none",
                             // backgroundColor: idx%2==0 ? "#e3eef1": null,
                             color: !householdMember.isActive ? "#6c6c6c" : null,
                         }}

                    >
                        <Col className="">
                            <Dropdown className="d-inline" style={{fontStyle: "normal"}}>
                                <Dropdown.Toggle variant="link" id="dropdown-basic" as={CustomToggle}>
                                    {householdMember.memberId != household.primaryMemberId ?
                                        <i className="bi bi-three-dots-vertical"></i>
                                        : null}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {householdMember.memberId != household.primaryMemberId
                                        // && householdMember.isActive
                                        ?
                                        <Dropdown.Item key={1}
                                                       onClick={() => handleMakePrimary(householdMember.member_id)}
                                        >
                                            Make Primary
                                        </Dropdown.Item> : null
                                    }
                                    <Dropdown.Item key={2}
                                                   onClick={() => handleMakeActive(householdMember.member_id, !householdMember.isActive)}>
                                        {householdMember.isActive ? "Deactivate" : "Activate"}
                                    </Dropdown.Item>
                                    <Dropdown.Divider></Dropdown.Divider>
                                    <Dropdown.Item key={3}
                                                   className="text-danger"
                                                   onClick={() => handleMemberRemove(householdMember.member_id)}
                                    >
                                        Remove from Household
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>

                            <Link to={`/member/${householdMember.member_id}`}>
                                <span>{`${householdMember.firstNm} ${householdMember.lastNm}`}</span>
                            </Link>
                            <span className="text-secondary">
                                        {household.primaryMemberId == householdMember.memberId ? " (primary)" : null}
                                    </span>
                        </Col>
                        <Col className="col-2 col-md-1 text-secondary">
                            {householdMember.age}
                        </Col>
                        <Col className="col-8 col-md-4 col-lg-4 mx-md-0 mx-3 text-secondary">
                            {householdMember.relationship}
                        </Col>
                    </Row>
                )) : null
            }
            <div className="text-end mt-3">
                <Button variant="link" className="dark-blue-link-button"
                        onClick={() => navigate('/member/create', {
                            state: {
                                organizationNumber: props.organizationNumber,
                                household_id: household._id,
                                relationship: "-"
                            }
                        })}>
                    <span>ADD NEW MEMBER</span>
                </Button>
                <Button variant="link" className="dark-blue-link-button m-0 p-0"
                        onClick={() => setShowAddExisting(!showAddExisting)}>
                    <span>ADD EXISTING MEMBER</span>
                </Button>
            </div>
        </div>
    );
}

export default MemberHousehold;