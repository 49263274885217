import Container from "react-bootstrap/Container";
import {useLocation, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import AxiosInstance from "../../AxiosInstance";
import CampaignRuns from "../../UIComponents/Campaign/CampaignRuns";
import NavigateBackMenu from "../../UIComponents/NavigateBackMenu";
import {Breadcrumb, Dropdown} from "react-bootstrap";
import CampaignAddOrganization from "../../UIComponents/Campaign/CampaignAddOrganization";
import {CustomToggle} from "../../Utils";
import {useNavigate} from "react-router";
import CampaignAddRun from "../../UIComponents/Campaign/CampaignAddRun";


const CampaignView = (props) => {


    const {campaignId} = useParams();
    const [campaign, setCampaign] = useState({});
    const [refreshRuns, setRefreshRuns] = useState(false)
    const {state} = useLocation();
    const [showAddParticipatingOrg, setShowAddParticipatingOrg] = useState(false);
    const [showAddRun, setShowAddRun] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {

        if (campaignId) {
            AxiosInstance.get(`/api/v1/campaign/` + campaignId).then((res => {
                setCampaign(res.data);
            }));
        }

    }, [campaignId])

    const handleParticipatingOrgAdded = () => {
        setShowAddParticipatingOrg(!showAddParticipatingOrg);
    }

    const handleRunAdded = () => {
        setShowAddRun(!showAddRun);
        setRefreshRuns(true)
    }

    return (
        <Container>
            <Breadcrumb className="my-2">
                <Breadcrumb.Item href="/organizations">Organizations</Breadcrumb.Item>
                {
                    state !== null && state.breadcrumb != null ?
                        state.breadcrumb.map((bc, idx) => (
                            <Breadcrumb.Item href={bc.link}>{bc.name}</Breadcrumb.Item>
                        )) : null
                }
                <Breadcrumb.Item active>{campaign.campaignName}</Breadcrumb.Item>
            </Breadcrumb>

            <div className="h5 mt-3">
                {campaign.campaignName}
                <Dropdown className="d-inline" style={{fontStyle: "normal"}}>
                    <Dropdown.Toggle variant="link" id="dropdown-basic" as={CustomToggle}>
                        <i className="bi bi-three-dots-vertical"></i>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item key={0} onClick={() => navigate('/campaign/create', {
                            state: {
                                campaign_id: campaignId
                            }
                        })}>
                            Edit
                        </Dropdown.Item>
                        <Dropdown.Item key={1} onClick={() => setShowAddRun(!showAddRun)}>
                            New Campaign Run
                        </Dropdown.Item>
                        <Dropdown.Item key={2} onClick={() => setShowAddParticipatingOrg(!showAddParticipatingOrg)}>
                            Add Organization
                        </Dropdown.Item>
                        {/*{householdMember.memberId != household.primaryMemberId*/}
                        {/*    // && householdMember.isActive*/}
                        {/*    ?*/}
                        {/*    <Dropdown.Item key={1}*/}
                        {/*                   onClick={() => handleMakePrimary(householdMember.member_id)}*/}
                        {/*    >*/}
                        {/*        Make Primary*/}
                        {/*    </Dropdown.Item>: null*/}
                        {/*}*/}
                        {/*<Dropdown.Item key={2} onClick={() => handleMakeActive(householdMember.member_id, !householdMember.isActive)}>*/}
                        {/*    {householdMember.isActive  ? "Deactivate" : "Activate"}*/}
                        {/*</Dropdown.Item>*/}
                        {/*<Dropdown.Divider></Dropdown.Divider>*/}
                        {/*<Dropdown.Item key={3}*/}
                        {/*               className="text-danger"*/}
                        {/*               onClick={() => handleMemberRemove(householdMember.member_id)}*/}
                        {/*>*/}
                        {/*    Remove from Household*/}
                        {/*</Dropdown.Item>*/}
                    </Dropdown.Menu>
                </Dropdown>
            </div>

            <CampaignAddRun campaign_id={campaign._id}
                            campaign_run_id={null}
                            show={showAddRun}
                            onAdded={handleRunAdded}/>

            <CampaignAddOrganization campaign_id={campaign._id}
                                     show={showAddParticipatingOrg}
                                     onAdded={handleParticipatingOrgAdded}/>

            {/* list campaign runs for the given campaign */}
            <CampaignRuns campaignId={campaignId} refresh={refreshRuns}/>

        </Container>
    );

}

export default CampaignView;