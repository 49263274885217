import React, {useState} from "react";

const DonationAmountView = (props) => {


    return (

        <div className="text-center">
            <div className=""
                 style={{fontSize:"24px", marginBottom: '0px'}}
            >
                Donation Amount
            </div>
            <div className="m-0"
                 style={{fontSize:"52px",
                     fontWeight:"bold",
                     lineHeight: 1,
                     color: "#0a1a86"}}>

                {props.processingFee ? `$`+ (props.amount + props.processingFee).toFixed(2) : `$` + props.amount.toFixed(2)}


            </div>
            {props.processingFee ?
                <div className="small text-secondary">
                    Includes ${props.processingFee.toFixed(2)} processing fee
                </div> : null}
            <div>
                {props.description?
                    <div>for <span className="h5">{props.description}</span></div> : null
                }

            </div>
        </div>

    );

}


export default DonationAmountView;