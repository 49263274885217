import React, {Fragment} from "react";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import AddressView from "../../UIComponents/Address/AddressView";
import PhoneEdit from "../../UIComponents/PhoneEdit";
import PhoneView from "../../UIComponents/PhoneView";
import {useNavigate} from "react-router";


const UpdatedAddressConfirmation = (props) => {

    const navigate = useNavigate();

    return (
        <Fragment>

            <Container className="col-md-5 col-sm-10">

                <div className="justify-content-center text-center mt-4">
                    <div className="row justify-content-md-center mb-2">
                        <div className="col-md-7 text-center">
                            <div className="h4 mb-1">Thank you!</div>
                            <div className="h5 mb-1">Your address has been updated.</div>
                        </div>
                    </div>

                    <div>
                        <AddressView address={props.address} />
                        <PhoneView  phoneNumber={props.address.phone} />
                    </div>

                    <Button variant="success" className="mt-4"
                            onClick={()=>navigate('/my/overview')}
                            block>
                        Continue
                    </Button>

                </div>
            </Container>

        </Fragment>

    );


}


export default (UpdatedAddressConfirmation); ;