import React, {Fragment, useEffect, useState} from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import {useNavigate} from "react-router";
import AxiosInstance from "../../AxiosInstance";
import {useLocation, useParams} from "react-router-dom";
import AddressEdit from "../../UIComponents/Address/AddressEdit";
import PhoneEdit from "../../UIComponents/PhoneEdit";
import API_V2 from "../../API_V2";


const MemberEdit = (props) => {


    const {state} = useLocation();

    const [formData, setFormData] = useState({
        organizationNumber: state ? state.organizationNumber: "---",
        household_id: state ? state.household_id: null
    });
    const navigate = useNavigate();
    const {memberId} = useParams();


    useEffect(() => {

        if (memberId != null) {
            // AxiosInstance.get('/api/v1/member/' + memberId).then((res => {
            //
            //     let birthDtISO = res.data.birthDt; //new Date(res.data.birthDt)
            //
            //     res.data.birthDt = `${birthDtISO.substring(5,7)}/${birthDtISO.substring(8,10)}/${birthDtISO.substring(0,4)}`
            //
            //     setFormData(res.data);
            // }));
            API_V2.get('/v2/members/' + memberId).then((res => {

                let birthDtISO = res.data.birthDt; //new Date(res.data.birthDt)
                res.data.birthDt = `${birthDtISO.substring(5,7)}/${birthDtISO.substring(8,10)}/${birthDtISO.substring(0,4)}`

                setFormData(res.data);
            }));
        }


    }, [memberId])


    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setFormData({
            ...formData,
            [name]: value
        });

    }

    const handleDateInputChange = (event) => {
        const target = event.target;
        const value = target.value.replace(/[^0-9]/g, '');
        const name = target.name;

        let temp_val = value;

        if (value.length > 4) {
            temp_val = temp_val.substr(0, 2) + '/' +
                temp_val.substr(2, 2) + '/' +
                temp_val.substr(4)
        } else if (value.length > 2) {
            temp_val = temp_val.substr(0, 2) + '/' +
                temp_val.substr(2)
        }


        setFormData({
            ...formData,
            [name]: temp_val
        });
        target.value = temp_val;
    }

    const handleUpdate = () => {

        let payload = {
            _id: formData._id,
            memberId: formData.memberId,
            lastNm: formData.lastNm,
            firstNm: formData.firstNm,
            middleInit: formData.middleInit,
            birthDt: new Date(formData.birthDt),
            organizationNumber: formData.organizationNumber,
            household_id: formData.household_id,
            relationship: formData.relationship,
            address: {...formData.address},
            // phones: [{},...formData.phones],
            // modifiedBy: localStorage.getItem("email"),
            // modifiedDt: Date.now()
        }

        if (formData.phones) {
            payload.phones = [...formData.phones]
        }

        console.log("payload: " + JSON.stringify(payload))



        if (formData.memberId) {
            payload.createdBy = localStorage.getItem("email");
            payload.createdDt = Date.now();
        }

        // AxiosInstance.post('/api/v1/member', payload).then((res => {
        //     navigate(-1);
        // }))
        API_V2.post('/v2/members', payload).then((res => {
            navigate(-1);
        }))

    }

    const handleAddressChange = (address) => {

        setFormData( {
            ...formData,
            "address": address
        });

    }

    const handlePhoneChange = (phone) => {

        setFormData( {
            ...formData,
            "phones": [{
                phoneNbr: phone,
            }]
        });

    }


    return (
      <Container>
          <Form className="d-grid gap-2">
              <Row>
                  <Col>
                      <Button variant="link" className="p-0 mt-3 mb-3" onClick={() => navigate(-1)}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor"
                               className="bi bi-arrow-left-circle" viewBox="0 0 16 16">
                              <path fillRule="evenodd"
                                    d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"/>
                          </svg>
                      </Button>
                  </Col>
              </Row>


              <Row>
                  <Col md={2}>
                      First Name
                  </Col>
                  <Col md={3}>
                      <Form.Control name="firstNm" required type="input"
                                    maxLength={100}
                                    value={formData.firstNm}
                                    onChange={handleInputChange}
                          // isInvalid={!!formErrors.streetAddress}
                                    placeholder="First Name"
                      />
                  </Col>

              </Row>
              <Row>
                  <Col md={2}>
                      Last Name
                  </Col>
                  <Col md={3}>
                      <Form.Control name="lastNm" required type="input"
                                    maxLength={100}
                                    value={formData.lastNm}
                                    onChange={handleInputChange}
                          // isInvalid={!!formErrors.streetAddress}
                                    placeholder="Last Name"
                      />
                      {/*<Form.Control.Feedback type="invalid">{formErrors.streetAddress}</Form.Control.Feedback>*/}
                  </Col>
              </Row>
              <Row>
                  <Col md={2}>
                      Birth Date
                  </Col>
                  <Col md={3}>
                      <Form.Control name="birthDt" required type="input"
                                    maxLength="10"
                                    value={formData.birthDt}
                                    // isInvalid={formErrors.birthDt}
                                    onChange={handleDateInputChange}
                                    placeholder="MM/DD/YYYY"
                                    autoComplete="disabled"/>
                      {/*<Form.Control.Feedback type="invalid">{formErrors.birthDt}</Form.Control.Feedback>*/}
                  </Col>
              </Row>
              <Row>
                  <Col md={2}>Relationship</Col>
                  <Col md={3}>
                      <Form.Select name="relationship"
                                   value={formData.relationship}
                                   onChange={handleInputChange}>
                          <option>Relationship in the household</option>
                          <option value="Head of Household">Head of Household</option>
                          <option value="Spouse">Spouse</option>
                          <option value="Son">Son</option>
                          <option value="Daughter">Daughter</option>
                          <option value="Child">Child</option>
                          <option value="Parent">Parent</option>
                          <option value="Son-In-Law">Son-In-Law</option>
                          <option value="Daughter-In-Law">Daughter-In-Law</option>
                      </Form.Select>
                  </Col>
              </Row>
              <Row>
                  <Col md={5}>
                      Address
                      <AddressEdit onChange={handleAddressChange} address={formData.address} />
                  </Col>
              </Row>
              <Row>
                  <Col md={5}>
                      Phone
                      <PhoneEdit onChange={handlePhoneChange}
                                 phoneNumber={formData.phones !== undefined &&
                                              formData.phones !== null &&
                                              formData.phones[0] !== undefined ?
                                              formData.phones[0].phoneNbr : null
                                              }
                      />
                  </Col>
              </Row>
              <Row >
                  <Col md={2}>
                  </Col>
                  <Col md={3} >
                      <Button variant="default" className="mt-2 d-block float-end"
                              // disabled={confirmedAddress}
                              onClick={handleUpdate}
                          >
                          {memberId ? "Update" : "Create"}
                      </Button>
                  </Col>
              </Row>

          </Form>
      </Container>
    );
}

export default MemberEdit;