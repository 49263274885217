import React, {useEffect, useState} from "react";
import AxiosInstance from "../../AxiosInstance";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PaginationStrip from "../PaginationStrip";
import {AgeCalculator} from "../../Utils";
import {Badge, Spinner} from "react-bootstrap";
import {Link} from "react-router-dom";
import Button from "react-bootstrap/Button";
import API_V2 from "../../API_V2";


const CampaignRuns = (props) => {

    const [campaignRuns, setCampaignRus] = useState();
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {

        API_V2.get(`/v2/campaigns/${props.campaignId}/runs/page/${currentPage}/size/10`).then((res => {
            setCampaignRus(res.data);
        }));


    }, [props.campaignId, currentPage, props.refresh])


    const handleActivate = (runId, active) => {

        const newCampaignRun = {
            _id: runId,
            isActive: active,
            modifiedBy: localStorage.getItem("email")
        }

        API_V2.post('/v2/campaign-run/', newCampaignRun).then((res => {
            API_V2.get(`/v2/campaigns/${props.campaignId}/runs/page/${currentPage}/size/10`).then((res => {
                setCampaignRus(res.data);
            }));

        }));
    }


    return (
        <div className="mt-3">
            <div className="h6 d-inline">Campaign Runs</div>
            {` `}<Badge pill bg="light" text="dark">
            {campaignRuns ? campaignRuns.results : null}
        </Badge>
            <hr/>


            {campaignRuns ?
                campaignRuns.campaignRuns.map((cr, idx) => (
                    <Row key={idx}>
                        <Col>
                            {
                                new Date(cr.endDt) > Date.now() && cr.isActive ?
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="green"
                                             className="bi bi-circle-fill" viewBox="0 0 16 16">
                                            <circle cx="8" cy="8" r="8"/>
                                        </svg>
                                        <Link style={{marginLeft: 5}}
                                              to={`/campaign/${props.campaignId}/run/${cr._id}`}>{cr.name}</Link>
                                        <Button size="sm"
                                                onClick={() => handleActivate(cr._id, false)}
                                                variant="outline-secondary"
                                                style={{paddingTop: 0, paddingBottom: 0, marginLeft: 10}}>
                                            <i className="bi bi-pause-fill"></i>Pause</Button>
                                    </div>
                                    : new Date(cr.endDt) > Date.now() ?
                                        <div>
                                            <Link className="text-secondary" style={{marginLeft: 15}}
                                                  to={`/campaign/${props.campaignId}/run/${cr._id}`}>{cr.name}</Link>
                                            <Button size="sm"
                                                    onClick={() => handleActivate(cr._id, true)}
                                                    variant="success"
                                                    style={{paddingTop: 0, paddingBottom: 0, marginLeft: 10}}>
                                                <i className="bi bi-play-fill"></i>Activate</Button>

                                        </div>
                                        :
                                        <div>
                                            <Link className="text-secondary" style={{marginLeft: 15}}
                                                  to={`/campaign/${props.campaignId}/run/${cr._id}`}>{cr.name}</Link>
                                        </div>
                            }
                            {` `}
                        </Col>
                    </Row>
                )) : null
            }
            <hr/>
            <PaginationStrip setCurrentPage={setCurrentPage} pages={campaignRuns ? campaignRuns.pages : null}/>
        </div>
    );

}

export default CampaignRuns;