import React, {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router";
import {json, useLocation} from "react-router-dom";
import Container from "react-bootstrap/Container";
import CreditCard from "../UIComponents/Payment/CreditCard";
import Button from "react-bootstrap/Button";
import AxiosInstance from "../AxiosInstance";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import CardsOnFile from "../UIComponents/Payment/CardsOnFile";
import {useCloverSDK} from "../CustomHooks/useCloverSDK";
import CloverCreditCard from "../UIComponents/Payment/CloverCreditCard";
import DonationAmountView from "../UIComponents/Donation/DonationAmountView";
import DonationLinkedAccountsToInclude from "../UIComponents/Donation/DonationLinkedAccountsToInclude";


const Donate = (props) => {


    const CHARGE_PROCESSING_FEE = true
    const CARD_PERCENT_FEE = 0.0125
    const PROCESSING_FEE = 10
    const BANK_FEE = 38

    const [formData, setFormData] = useState({totalDonation: 0, memberships:[]});
    const [useCardOnFile, setUseCardOnFile] = useState(true);
    const [hasCardsOnFile, setHasCardsOnFile] = useState(false);
    const [saveCard, setSaveCard] = useState(false);
    const [linkedAccounts, setLinkedAccounts] = useState([]);
    const [formErrors, setFormErrors] = useState({});
    const navigate = useNavigate();
    const {state} = useLocation();
    const cloverRef = useRef();


    useEffect(() => {

        // Promise.all([
        //     getAmountDue(state.member_id, state.campaign_id),
        //     getLinkedAccounts()
        // ]).then(result => {
        //
        //     setLinkedAccounts(result[1]);
        //
        //     setFormData({
        //         ...formData,
        //         memberships: [result[0]],
        //         totalDonation:[result[0]].balance
        //     });
        //
        // })

        // const elements = clover.elements();

        // console.log("clover elements: " + JSON.stringify(elements));


        getAmountDue(state.member_id, state.campaign_id).then(balanceRes => {
            getLinkedAccounts().then(laccounts => {
                    setLinkedAccounts(laccounts);
                })
            setFormData({
                ...formData,
                donation_id: balanceRes.donation_id,
                memberships: [{...balanceRes, isPrimary:true}],
                totalDonation:balanceRes.balance,
                processingFee: calculateProcessingFee(balanceRes.balance)
            });

        })

    }, [state]);

    /* TODO:
        * [x] get balance for the props member_id
        * check if props.member has linked accounts
        * if yes list linked accounts that also members of the same campaign
        * promt if payee wants also pay for the selected linked accounts
        * Lear How to perform multiple requests concurrently
        Promise.all([getUserAccount(), getUserPermissions()])
          .then(function (results) {
            const acct = results[0];
            const perm = results[1];
          });

     */

    const getLinkedAccounts = async () => {

        const {data} = await AxiosInstance.get(`/api/v1/member/${state.member_id}/household/linked`);
        let linkedAccountsRes = [];
        // calculate totals

        for (let i=0; i< data.length; i++) {
            let balanceRes = await getAmountDue(data[i].primary_member_id, state.campaign_id);

            // include only l-accounts w/balance
            if (balanceRes.balance > 0) {
                linkedAccountsRes.push({
                   ...data[i],
                    membership: balanceRes
                });
            }
        }

        return linkedAccountsRes;
    }

    const getAmountDue = async (member_id, campaign_id) => {

        console.log(`getAmountDue: member_id: ${member_id} campaign_id: ${campaign_id}`)
        const balanceRes = await AxiosInstance.get(`/api/v1/member/${member_id}/${campaign_id}/balance`);
        console.log(`getAmountDue: member_id:${member_id} campaign_id:${campaign_id}`)
        console.log(`balance res: ${JSON.stringify(balanceRes.data)}`)
        return balanceRes.data;

    }

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        console.log(`name: ${name} | value: ${value} `)

        let newFormData = {
            ...formData,
            [name]: value
        }

        delete formErrors[name];
        setFormErrors({...formErrors});

        setFormData(newFormData);

    }


    const handleLinkedAccountSelected = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const member_id = target.name;

        (async () => {

            let newState = {
                ...formData,
            }

            if (value) { // add amount to total
                let donationDue = await getAmountDue(member_id,state.campaign_id);
                newState.memberships = [...formData.memberships, donationDue];
                newState.totalDonation += donationDue.balance;
                newState.processingFee = calculateProcessingFee(newState.totalDonation); // recalculate
            } else { //remove amount from total

                let existsAt = -1;
                for (let i=0; i<newState.memberships.length; i++) {
                    if (newState.memberships[i].member_id == member_id) {
                        existsAt = i;
                    }
                }

                if (existsAt !== -1) {
                    newState.totalDonation -= newState.memberships[existsAt].balance;
                    newState.processingFee = calculateProcessingFee(newState.totalDonation); // recalculate
                    newState.memberships.splice(existsAt, 1);

                }

            }

            // console.log("newState: " + JSON.stringify(newState));
            setFormData(newState);

        })();

        // setFormData(newFormData);

    }

    const calculateProcessingFee = (amount) => {
        if (CHARGE_PROCESSING_FEE) {
            const fee = Number(Math.round(((amount) * CARD_PERCENT_FEE + ((PROCESSING_FEE + BANK_FEE) / 100)) + 'e2') + 'e-2')
            return fee;
        } else {
            return 0;
        }
    }

    const handleCardValidated = (cardData) => {

        // augment card data
        cardData = {...cardData, saveCard:saveCard, existingCard:useCardOnFile}
        console.log("handleCardValidated cardData: " + JSON.stringify(cardData) );

        setFormData({...formData, validatedCard: cardData});

        let donations = [];
        formData.memberships.map((m,idx) => (
            donations.push({
                donation_id: m.donation_id,
                amount:m.balance,
                extRef:m.donationExtRef,
                isPrimary: m.isPrimary})
        ));

        const statePayload = {state: {
                campaign_id: state.campaign_id,
                campaignName: state.campaignName,
                totalAmount: formData.totalDonation,
                processingFee: formData.processingFee,
                cardData: cardData,
                donation_id: formData.donation_id,
                donations: donations

            }}
        console.log("state: " + JSON.stringify(statePayload));
        navigate("/donate/review", statePayload);

    }

    const handleSelectedExistingCard = (cardData) => {
        // augment card data
        cardData = {...cardData, saveCard:saveCard, existingCard:useCardOnFile}
        setFormData({...formData, validatedCard: cardData});
    }

    const handleHasCardsOnFile = (e) => {
        setUseCardOnFile(e);
        setHasCardsOnFile(e);
    }

    const handleContinue = () => {

        if (useCardOnFile) {

            if (formData.validatedCard && formData.validatedCard.token !== null) {
                console.log("I am here");
            }

            let donations = [];
            formData.memberships.map((m,idx) => (
                donations.push({
                    donation_id: m.donation_id,
                    amount:m.balance,
                    extRef:m.donationExtRef,
                    isPrimary: m.isPrimary})
            ));

            navigate("/donate/review", {state: {
                    campaign_id: state.campaign_id,
                    campaignName: state.campaignName,
                    totalAmount: formData.totalDonation,
                    processingFee: formData.processingFee,
                    cardData: formData.validatedCard,
                    donation_id: formData.donation_id,
                    donations: donations

                }});

        } else {
            cloverRef.current.getToken();
        }


    }


    return (
        <Container>

            <div className="m-auto col-sm-10 col-md-8 col-lg-6 col-xl-5 mt-3 mb-5">

                <div className="mt-3 mb-4">
                    <DonationAmountView
                        amount={formData.totalDonation}
                        processingFee={formData.processingFee}
                        description={state.campaignName}
                    />
                </div>

                <div>
                    {linkedAccounts.length > 0 ?
                        <DonationLinkedAccountsToInclude
                            accounts={linkedAccounts}
                            onAccountSelected={handleLinkedAccountSelected}
                        />
                        : null}
                </div>


                <div className="mt-3 mb-5">
                    <div className="">
                        <div className="grid-header px-2">
                            Payment Method
                        </div>

                        <Form>
                            <Form.Check
                                label="Use New Credit Card"
                                checked={!useCardOnFile}
                                onChange={handleInputChange}
                                onClick={() => setUseCardOnFile(false)}
                                name="payment-method"
                                type="radio"
                                id="one-time-payment"
                            />
                            <Form.Check
                                label="Existing Credit Card"
                                checked={useCardOnFile}
                                onChange={handleInputChange}
                                disabled={hasCardsOnFile?false:true}
                                onClick={() => setUseCardOnFile(true)}
                                name="payment-method"
                                type="radio"
                                id="existing-payment"
                            />
                        </Form>
                    </div>

                    {!useCardOnFile?
                        <div className="m-auto mt-2">
                            <CloverCreditCard width="100%" ref={cloverRef} onCardValidated={handleCardValidated}/>

                            <div className="mt-2">
                                <Form.Check
                                    label="Save Card for Future Payments"
                                    disabled={true}
                                    onClick={() => setSaveCard(!saveCard)}
                                    name="save-card"
                                    type="checkbox"
                                    id="save-card"
                                />
                            </div>

                        </div>


                        : <div className="col-md-6 mt-3">
                            <CardsOnFile member_id={state.payee_member_id}
                                         hasCards={handleHasCardsOnFile}
                                         onSelected={handleSelectedExistingCard} />
                        </div>

                    }


                    <div className="mt-5 text-center mb-5"

                    >
                        <Button size="lg"
                                onClick={handleContinue}
                                disabled={(useCardOnFile && hasCardsOnFile && formData.validatedCard && formData.validatedCard.token) ||
                                    (!useCardOnFile) ? false:true}
                                variant="default"
                                className="px-5">
                            Continue
                        </Button>
                    </div>
            </div>
            </div>
        </Container>
    );
}

export default Donate;