import React, {Fragment, useState} from 'react';
import Form from "react-bootstrap/Form"
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import AxiosInstance from '../../AxiosInstance.js'
import {useSearchParams} from "react-router-dom";
import {useNavigate} from "react-router";


// Please check  vitaliy@gogolevs.com for a link to create a new password for your Arlo account. If you don't see the email, check your spam, or tap "Resend email", below.
// You requested to reset your Arlo account password. To complete the process, please click the “Reset Password” button below.
// copy and paste the following link into your browser’s address bar:

const ConfirmEmail = (props) => {

    AxiosInstance.defaults.headers['callbackUrl'] = window.location.origin;

    const [searchParams] = useSearchParams();
    const email = searchParams.get("email");
    const code = searchParams.get("code");

    const [formErrors, setFormErrors] = useState({});
    const [formData, setFormData] = useState({"email": email, "code": code});
    const [valid, setValid] = useState(false);
    const [resent, setResent] = useState(false);
    const navigate = useNavigate();


    if (!email) {
        navigate('/Sign-Up');
    }

    const handleResend = (e) => {
        e.preventDefault();
        e.stopPropagation();

        AxiosInstance.post('/confirm-email/resend', {"email": email})
            .then((res) => {
                console.log(res)
                setResent(true);
                setTimeout(() => navigate('sign-in'), 1000 );

            })


    }

    const handleConfirm = (event) => {
        event.preventDefault();
        event.stopPropagation();
        localStorage.removeItem("userToken");

        let errors = {};

        !/EV[A-Z0-9]{4,}/i.test(formData.code) || !formData.code ? errors.code = "Not valid" :
            delete errors.code;

        if (Object.keys(errors).length === 0) {

            AxiosInstance.post('/confirm-email', {"email": email, "code": formData.code})
                .then((res) => {
                    console.log(res)

                    setValid(true);

                    setTimeout(() => navigate('/sign-in'), 1000);

                }).catch((err) => {

                errors.code = err.response.data;
                if (err.response.status === 404) {
                    errors.code = "The code is not valid or expired"
                }
                setFormErrors({...errors})
            });
        }


        setFormErrors({...errors})

    };

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setFormData({
            ...formData,
            [name]: value
        });
    };

    return (
        <div className="container">
            <div className="row justify-content-md-center mt-5">
                <div className="col-md-5 text-center">
                    <div className="h4 mb-1">Confirm Your Email</div>
                    <div className="text-muted small">
                        Please check {email} for a link to confirm your email address and activate your MyFundCenter account. If you don't see the email, check your spam, or tap "Resend Email" below.
                    </div>

                </div>
            </div>
            <div className="row justify-content-md-center mt-3">
                <Form className="col-md-5 text-center">
                    <Form.Group>
                        <InputGroup className="mt-2">
                            <Form.Control name="code" className="text-center" required type="input"
                                          defaultValue={code ? code : ""}
                                          isInvalid={formErrors.code}
                                          isValid={valid}
                                          placeholder="Confirmation Code" onChange={handleInputChange}/>
                            <Form.Control.Feedback type="invalid">{formErrors.code}</Form.Control.Feedback>
                        </InputGroup>

                        <div className="d-grid gap-2 mt-2">
                            <Button variant="default" type="submit" block onClick={handleConfirm}>
                                Confirm
                            </Button>
                            <Button variant="secondary" type="submit" block disabled={resent} onClick={handleResend}>
                                Resend Email
                            </Button>
                            <Form.Control.Feedback type="valid">
                                The verification code has been sent
                            </Form.Control.Feedback>

                        </div>
                    </Form.Group>
                </Form>
            </div>
        </div>

    );
}

export default ConfirmEmail;