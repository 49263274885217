import {Fragment, useEffect, useState} from "react";
import {useNavigate} from "react-router";
import Card from "react-bootstrap/Card";
import AxiosInstance from "../../AxiosInstance";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {formatDate} from "../../Utils";


const MyRecentDonations = (props) => {


    const [donations, setDonations] = useState([]);

    useEffect(() => {

        if (props.member_id !== "undefined") {
            AxiosInstance.get(`/api/v1/member/${props.member_id}/donations/recent`).then(res => {
                setDonations(res.data);
            });
        }

        }, [props.member_id]);

    return (
        <Card>
            <Card.Header style={{backgroundColor: "#073e7e", color:"white"}}>Recent Donations</Card.Header>
            <Card.Body className="pt-1">
                {/*<Row className="grid-header">*/}
                {/*    /!*<Col md={7} xs={4} className="text-start"></Col>*!/*/}
                {/*    <Col md={3} xs={4} lg={3} className="align-content-start ml-md-3">Date</Col>*/}
                {/*    <Col className="col-1"></Col>*/}
                {/*    <Col md={4} xs={5} lg={4} className="mr-1">Amount</Col>*/}

                {/*</Row>*/}
                {donations !== undefined ?
                    donations.map( (d,idx) => (
                    <Row key={idx}>
                        <Col className="px-0">
                            {formatDate(d.modifiedDt)}
                        </Col>

                        <Col md={4} xs={5} lg={4} className="">
                            {`$ ` + d.paymentsTotal.toFixed(2)}
                        </Col>

                    </Row>
                )): null}
            </Card.Body>
            <Card.Footer>
                <div className="text-secondary small">
                    * <u>only</u> the last 5 donations are shown.
                </div>
            </Card.Footer>
        </Card>
    );
}

export default MyRecentDonations;