import {Fragment, useEffect, useState} from "react";
import {useNavigate} from "react-router";
import Card from "react-bootstrap/Card";
import AxiosInstance from "../../AxiosInstance";
import Button from "react-bootstrap/Button";
import {Link} from "react-router-dom";
import API_V2 from "../../API_V2";


const MyCampaigns = (props) => {


    const [memberCampaigns, setMemberCampaigns] = useState([]);
    const [formData, setFormData] = useState({});
    const [formErrors, setFormErrors] = useState({});
    const [showSubscription, setShowSubscription] = useState(false);
    const navigate = useNavigate();


    useEffect(() => {

        if (props.member_id !== "undefined") {
            // AxiosInstance.get(`/api/v1/member/${props.member_id}/campaigns?active=true`).then(res => {
            //     setMemberCampaigns(res.data);
            // });

            API_V2.get(`/v2/members/${props.member_id}/campaigns?active=true`).then(res => {
                setMemberCampaigns(res.data);
            });


        }

    }, [props.member_id]);


    const handleDonate = (campaign_id, campaignName, membership_id) => {
        navigate('/donate', {state: {
                member_id: props.member_id,
                payee_member_id: props.payee_member_id,
                campaign_id: campaign_id,
                membership_id: membership_id,
                campaignName: campaignName
            }});
    }

    return (
        <Card md={5}>
            <Card.Header style={{backgroundColor: "#073e7e", color:"white"}}>Active Campaigns</Card.Header>
            <Card.Body className="pb-0">
                {memberCampaigns.length > 0?
                    memberCampaigns.map( (c,idx) => (
                    <Fragment key={idx}>
                        <Card.Title>
                            <i className="bi bi-play-fill" style={{color: "#073e7e"}}></i>
                            {c.campaignName}
                            {c.currentCampaignRunsBalance > 0 ? null
                                : <div className="d-inline float-end fw-bold">
                                    <i className="bi bi-check2-circle " style={{color: "#ab0b0b",fontSize:"18px"}}>{` `}PAID</i>
                                  </div>
                            }

                        </Card.Title>
                        <Card.Body className="mt-0 pt-0">
                            <div>
                                <div className="d-inline col-2">{c.shortDescription}</div>
                            </div>
                            <div className="small mt-1">
                                <i className="bi bi-check-circle-fill text-success"></i>
                                <span className="px-2 text-secondary">
                                    You are subscribed,{` `}
                                    <Link variant="link"
                                          style={{color: "#81610e", padding: "0", margin: "0"}}
                                    >
                                        <span>see details.</span>
                                    </Link>

                                </span>

                                <div className="justify-content-center d-flex m-auto pt-3">
                                    {c.currentCampaignRunsBalance > 0 ?
                                    <Button
                                        className="btn-yellow px-4"
                                        disabled={c.currentCampaignRunsBalance > 0 ? false : true}
                                        size=""
                                        onClick={() => handleDonate(c.campaignId, c.campaignName, c.campaignMembershipId)}>
                                        Donate Now
                                    </Button> : null}
                                </div>

                            </div>
                            {/*<hr/>*/}
                        </Card.Body>
                    </Fragment>
                    ))
                    :
                    <div className="text-primary">
                        <i className="bi bi-info-circle-fill"/>{` `}
                        No active campaigns found
                    </div>
                }
            </Card.Body>
        </Card>
    );
}

export default MyCampaigns;