import React, {useEffect, useState} from "react";
import {Pagination} from "react-bootstrap";
import { useMediaQuery } from 'react-responsive'


const PaginationStrip = (props) => {



    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(10);
    const [displayPages, setDisplayPages] = useState(10);
    const [startingIndex, setStartingIndex] = useState(1);
    const [paginationItems, setPaginationItems] = useState([]);


    const isTabletOrMobile = useMediaQuery({
        query: '(max-width: 1224px)'
    })



    useEffect( () => {

        getItems();
        setTotalPages(props.pages);

        if (isTabletOrMobile) {
            setDisplayPages(4);
        } else {
            setDisplayPages(10);
        }

        props.setCurrentPage(currentPage);

    }, [props.pages, currentPage, startingIndex, totalPages, isTabletOrMobile]);



    const getItems = () => {

        let items = [];

        for (let i=startingIndex;
             i < startingIndex + displayPages &&
             i <= totalPages;
             i++) {

            items.push(
                <Pagination.Item
                    key={i}
                    active={currentPage === i? true : false}
                    onClick={() => {setCurrentPage(i)}}
                >{i}</Pagination.Item>
            )
        }


        setPaginationItems(items);


    }


    return (

        totalPages < 2? null :

        <Pagination size="sm" className="justify-content-center">
            <Pagination.First
                disabled={currentPage === 1? true:false}
                onClick={ ()=> {
                    setStartingIndex(1);
                    setCurrentPage(1);
                }
                }
            />
            <Pagination.Prev
                disabled={currentPage === 1? true: false}
                onClick={()=> {

                    if (currentPage === startingIndex) {
                        setStartingIndex(currentPage - displayPages);
                        setCurrentPage(currentPage-1)
                    } else {
                        setCurrentPage(currentPage-1)
                    }


                }}
            />
            {paginationItems}
            <Pagination.Next
                disabled={currentPage === totalPages? true:false}
                onClick={()=> {

                    if (currentPage === startingIndex + displayPages -1) {
                        setStartingIndex(currentPage + 1);
                        setCurrentPage(currentPage+1);
                    } else {
                        setCurrentPage(currentPage+1)
                    }


                }}
            />
            <Pagination.Last
                disabled={currentPage === totalPages? true:false}
                onClick={ ()=> {

                    setStartingIndex( totalPages < displayPages ? 1 : totalPages - displayPages + 1);

                    setCurrentPage(totalPages)

                    }
                }
            />
        </Pagination>
    );

}


export default PaginationStrip;