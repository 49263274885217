import Container from "react-bootstrap/Container";
import React, {useEffect, useState} from "react";
import AxiosInstance from "../../AxiosInstance";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PaginationStrip from "../PaginationStrip";


const CampaignRunDonations = (props) => {


    const [donations, setDonations] = useState({donations:[]});
    const [currentPage, setCurrentPage] = useState(1);


    useEffect(() => {

        AxiosInstance.get(`/api/v1/campaign-run/${props.campaignRunId}/donations/page/${currentPage}/size/20`).then((res => {
            setDonations(res.data);
        }));

    }, [props.campaignRunId, currentPage])


    return (
        <Container>
            <div className="h5 mt-3">Donations</div>

            {donations.donations.map((donation, idx) => (
                <Row key={idx}>
                    <Col md={4}>
                        {donation.primaryMemberName}
                    </Col>
                    <Col>
                        {`$ ${donation.paymentsTotal.toFixed(2)}`}
                    </Col>
                    <Col>
                        {donation.externalRef}
                    </Col>
                </Row>
            ))}
            <hr/>
            <div>
                <PaginationStrip setCurrentPage={setCurrentPage}
                                 pages={donations.pages} />
            </div>
        </Container>
    )


}

export default CampaignRunDonations;