
import React, {useEffect, useState} from "react";
import Form from "react-bootstrap/Form";
import {Badge, FormControl, FormGroup} from "react-bootstrap";
import AxiosInstance from "../../AxiosInstance";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import AddressView from "../../UIComponents/Address/AddressView";
import {Link, useSearchParams} from "react-router-dom";
import Pagination from "../../UIComponents/PaginationStrip";
import PaginationStrip from "../../UIComponents/PaginationStrip";
import {AgeCalculator} from "../../Utils";
// import organization from "./Organization";
import OrganizationCampaigns from "../../UIComponents/Organization/OrganizationCampaigns";
import GroupsViewTab from "../../UIComponents/Groups/GroupsViewTab";
import {useNavigate} from "react-router";
import PhoneView from "../../UIComponents/PhoneView";

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

const OrganizationView = (props) => {


    const [members, setMembers] = useState();
    const [hasMembers, setHasMembers] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState();
    const navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams()


    useEffect(() => {

        if (props.organization.organizationNumber) {
            AxiosInstance.get(`/api/v1/organization/${props.organization.organizationNumber}/members/page/${currentPage}/size/20/?searchTerm=${searchTerm}`).then((res => {
                setMembers(res.data);
            }));
            AxiosInstance.get(`/api/v1/organization/${props.organization.organizationNumber}/members/page/1/size/1/?searchTerm=`).then((res => {
                setHasMembers(res.data.results > 0? true : false);
            }));
        }

    }, [props.organization, currentPage,searchTerm])

    const handleUpdate = () => {
        props.onEdit();
    }

    const handleDelete = () => {

        if (!hasMembers && !props.organization.hasChildren && props.organization.hasCampaigns ) {
            AxiosInstance.delete(`/api/v1/organization/${props.organization.organizationNumber}`).then((res => {
                props.onCancel();
            }));
        }
    }

    const handleSearch = (e) => {

        let target = e.target;

        if (target.value) {

            setTimeout( () => {
                setSearchTerm(target.value);
            }, 2000)

        }


    }

    const handleCampaignsOnClick = (key) => {
        navigate(`/campaign/${key}`, {state: {
                breadcrumb: [
                    {
                        name: props.organization.organizationName,
                        link: "/organizations/" + props.organization.organizationNumber
                    }
                ]
            }});
    }



    return (
        <Container>
            <Row className="h5 mb-0" >
                <Col>
                    {props.organization.organizationName}
                    <Button variant="link" onClick={handleUpdate} className={"p-0 pb-2 mt-0 "} >
                        <svg xmlns="http://www.w3.org/2000/svg" className={"text-secondary align-text-top"} height="24" width="24" fill="currentColor" viewBox="0 0 48 48">
                            <path d="M9 47.4q-1.2 0-2.1-.9-.9-.9-.9-2.1v-30q0-1.2.9-2.1.9-.9 2.1-.9h20.25l-3 3H9v30h30V27l3-3v20.4q0 1.2-.9 2.1-.9.9-2.1.9Zm15-18Zm9.1-17.6 2.15 2.1L21 28.1v4.3h4.25l14.3-14.3 2.1 2.1L26.5 35.4H18v-8.5Zm8.55 8.4-8.55-8.4 5-5q.85-.85 2.125-.85t2.125.9l4.2 4.25q.85.9.85 2.125t-.9 2.075Z"/>
                        </svg>
                    </Button>

                </Col>
            </Row>
            <Row className="mt-0">
                <Col>
                    <AddressView address={props.organization.address} />
                    {
                        props.organization !== undefined &&
                        props.organization !== null &&
                        props.organization.phones !== undefined &&
                        props.organization.phones !== null ?
                        props.organization.phones.map((phone, idx) => {
                            if (phone.phoneNbr !== undefined && phone.phoneNbr !== null) {
                                return <PhoneView key={idx} phoneNumber={phone.phoneNbr}/>
                            } else {
                                return null;
                            }

                        }): null
                    }
                    <div>Auth Code: {searchParams.get('code')}</div>
                </Col>
            </Row>

            <Row className="mt-3">
                <Col>

                    <Tabs
                        defaultActiveKey="campaigns"
                        id="uncontrolled-tab-example"
                        className="mb-3"
                    >
                        <Tab eventKey="campaigns" title={
                            <span>Campaigns{` `}
                                <Badge pill bg="light" text="dark">
                                                {props.organization.campaignsCnt}
                                         </Badge></span>
                            }>
                            <OrganizationCampaigns
                                organizationNumber={props.organization.organizationNumber}
                                onClick={handleCampaignsOnClick}
                            />
                        </Tab>

                        <Tab eventKey="groups" title={
                            <span>Groups{` `}
                                <Badge pill bg="light" text="dark">
                                                {props.organization.groupsCnt}
                                         </Badge></span>
                        }>
                            <GroupsViewTab
                                onGroupAdded={props.onGroupAdded}
                                organizationNumber={props.organization.organizationNumber} />
                        </Tab>

                        <Tab eventKey="members"
                             title={members ?
                                        <span>Members{` `}
                                        <Badge pill bg="light" text="dark">
                                                {members.results}
                                         </Badge></span> : "Members"
                                    }>

                            {/* TODO: make into a component */}

                            <Row>
                                <Col>
                                    <Button variant="" size=""
                                            onClick={()=> navigate('/member/create', {state: {
                                                    organizationNumber: props.organization.organizationNumber
                                                }})}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                             className="bi bi-plus-circle" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                            <path
                                                d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                        </svg>{` `}Add New
                                    </Button>
                                </Col>
                                <Col md={2}>
                                    <FormControl type="input" placeholder="Search" onKeyDown={handleSearch}></FormControl>
                                </Col>
                            </Row>
                            {hasMembers ?
                                <div className={"mt-3 mb-5 pb-5"}>



                                    <hr className="my-2"/>



                                    {
                                        members ?
                                            members.members.map( (m, idx) => (
                                                <div key={idx}><Link to={`/member/${m._id}`}>{m.lastNm}{` `}{m.firstNm}</Link>{` `}
                                                    <Badge pill bg="light" text="dark">
                                                        {AgeCalculator(m.birthDt)}
                                                    </Badge>

                                                </div>

                                            )) : null
                                    }
                                    <hr/>
                                    <div>
                                        <PaginationStrip setCurrentPage={setCurrentPage}
                                                         pages={members ? members.pages : null} />
                                    </div>
                                </div> : false
                            }
                        </Tab>

                        <Tab eventKey="households" title="Households" disabled></Tab>

                    </Tabs>


                </Col>
            </Row>


            <div className={"text-center mt-5"}>
                {!hasMembers &&
                !props.organization.hasChildren &&
                !props.organization.hasCampaigns &&
                 props.organization.groupsCnt == 0 ?
                    <Button variant="danger" onClick={handleDelete}>
                        Remove Organization
                    </Button> : null
                }
            </div>

        </Container>

    );
}

export default OrganizationView;