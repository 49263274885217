import {Fragment, useEffect, useState} from "react";
import {useNavigate} from "react-router";
import Card from "react-bootstrap/Card";
import AxiosInstance from "../../AxiosInstance";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import MemberHouseholdAdd from "../Member/MemberHouseholdAdd";


const MyCampaigns = (props) => {


    const [household, setHousehold] = useState({});
    const [formData, setFormData] = useState({});
    const [formErrors, setFormErrors] = useState({});
    const [showAddNew, setShowAddNew] = useState(false);
    const [dirty, setDirty] = useState(false);
    const navigate = useNavigate();


    useEffect(() => {
        if (props.member_id !== "undefined") {
            getHouseholdSorted().then(household => {
                setHousehold(household);
            });
        }
        }, [props.member_id, dirty]);


    const getHouseholdSorted = async () => {

        let householdRes  = await AxiosInstance.get(`/api/v1/member/${props.member_id}/household?active=True`);

        if (householdRes.data.members !== undefined && householdRes.members !== null) {
            householdRes.data.members.sort((a,b) => {

                if (a.memberId == householdRes.data.primaryMemberId) {
                    return -1;
                } else if (a.isActive && !b.isActive) {
                    return -1;
                } else {
                    return 0;
                }

            } );
        }

        return householdRes.data;
    }

    return (
        <Fragment>
            <MemberHouseholdAdd show={showAddNew}
                                organizationNumber={household.organizationNumber}
                                household_id={household._id}
                                onSubmitted={()=>setDirty(!dirty)}
                                onClosed={setShowAddNew} />
            <Card>
                <Card.Header style={{backgroundColor: "#073e7e", color:"white"}}>Household</Card.Header>
                <Card.Body className="pt-1">
                    <Row className="grid-header">
                        <Col xs={10}>Name</Col>
                        <Col md={2} xs={2}>Age</Col>
                    </Row>
                    {household.members !== undefined ?
                        household.members.map( (m,idx) => (
                            <div key={idx} style={{
                                fontStyle: m.status == 'PENDING' ? "italic":null,
                                color: m.status == 'PENDING' ? "grey":null,
                            }}>
                                <Row>
                                    <Col xs={10}>
                                        <div>
                                            <i className="bi bi-person-circle text-secondary pl-2"
                                               style={{fontSize:"18px"}}
                                            ></i>{` `}
                                            {m.firstNm + ' ' + m.lastNm}
                                            {localStorage.getItem("memberId") == m.member_id?
                                                <span className="text-secondary">{` `}(you)</span>
                                                :null
                                            }
                                        </div>
                                    </Col>
                                    <Col md={2} xs={2}>
                                        {m.age}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="small text-secondary">
                                            {m.relationship}

                                        </div>
                                    </Col>
                                    <Col>
                                        {m.status == 'PENDING' ?
                                            <div className="small" style={{color:"#d79a03"}}>
                                                (Pending Approval)
                                            </div>: null
                                        }
                                    </Col>
                                    <hr className="mb-1"/>
                                </Row>
                            </div>
                    )): null}
                    <div className="d-grid mt-2">
                        <span className="text-secondary small pb-2">
                            <i className="bi bi-info-circle-fill"
                               style={{fontSize: '16px', color:"#073e7e"}}>{` `}</i>
                            Please note that adding a new household member DOES NOT
                            add them to any campaign subscriptions.
                            You will need to do that separately.</span>
                        <Button className="btn-outline-default p-0"
                                onClick={()=> setShowAddNew(!showAddNew)}
                        >
                            <i className="bi bi-person-add" style={{fontSize:"24px"}}>
                                {` `}
                            </i>
                            Add Household Member
                        </Button>

                    </div>
                </Card.Body>
            </Card>
        </Fragment>
    );
}

export default MyCampaigns;