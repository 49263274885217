import React from 'react'
import AppConfig from "../Config/AppConfig";

const footer =() => {

    return (

        <footer className="font-small bg-dark-blue fixed-bottom">
            <div className="container">
                <div className="mt-2">
                    <a href="/terms"><span className="light-link">Terms</span></a>
                    <span className="mx-2 light-link">|</span>
                    <a href="/privacy-notice"><span className="light-link">Privacy Notice</span></a>
                </div>
            </div>
            <div className="footer-copyright text-secondary text-center py-1">© 2022 Copyright:&nbsp;
                <a className="text-secondary mr-2" href="/">MFC</a>{` `}v{AppConfig().global.version}
            </div>
        </footer>
    );

};

export default footer