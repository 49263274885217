import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import {useEffect, useState} from "react";

const DonationLinkedAccountsToInclude = (props) => {

    const [accounts, setAccounts] = useState([]);

    useEffect(()=>{

        setAccounts(props.accounts);

    }, [props.accounts])

    // expected prop: accounts

    return (
        <div>
            <div className="grid-header px-2">
                Linked Accounts to Include
            </div>

            <div>
                {accounts.map((li, idx) => (
                    <div>
                    <Row key={idx} className="m-auto">
                        <Col md={8} xs={8}>
                            <Form.Check name={li.primary_member_id}
                                        required type="checkbox"
                                        onChange={props.onAccountSelected}
                                        label={li.primaryMemberName}

                            />

                        </Col>
                        <Col>
                            {`$ `+ li.membership.balance.toFixed(2)}
                        </Col>
                    </Row>
                    </div>
                ))
                }
            </div>
        </div>
    );

}

export default DonationLinkedAccountsToInclude;