import React, {Fragment, useEffect, useState} from "react";
import {useNavigate} from "react-router";
import Form from "react-bootstrap/Form";
import AxiosInstance from "../../AxiosInstance";


const CardsOnFile = (props) => {


    const [formData, setFormData] = useState({});
    const [formErrors, setFormErrors] = useState({});
    const [cards, setCards] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        AxiosInstance.get(`/api/v1/member/${props.member_id}/cards`).then(res => {
            setCards(res.data);
            if (res.data.length > 0) {
                props.hasCards(true);
            } else {
                props.hasCards(false);
            }

        });
    },[props.member_id]);


    const getCardOptions = () => {

        let options = [];

        if (cards.length == 0) {
            options.push(<option key={-1}>No Cards on File</option>);
        } else {
            options.push(<option key={-1} value={-1}>Select Payment Card</option>);
            cards.map((c, idx) => {
                options.push(
                    <option key={idx} value={c.id}>{c.cardType + `...` + c.last4}</option>
                )
            })
        }

        return options;
    }

    const handleCardOnChange = (e) => {
        // console.log("cardData: " + JSON.stringify());

        if (e.target.value == -1) {
            props.onSelected(null);
        }
        const existingCardData = cards.find((c) => c.id === e.target.value);

        if (existingCardData != undefined) {

            const cardData = {
                card: {
                    exp_month: existingCardData.expirationDate.substring(0,2),
                    exp_year: existingCardData.expirationDate.substring(2),
                    first6: existingCardData.first6,
                    last4: existingCardData.last4,
                    brand: existingCardData.cardType
                },
                existingCard: true,
                saveCard: false,
                token: existingCardData.token
            }
            setFormData(cardData);
            props.onSelected(cardData);

        }

    }



    return (
        <Fragment>
            {/*{addCardDialog}*/}
            <Form.Select name="existing_card"
                         size="sm"
                // disabled={formData.member_id != null? false:true}
                         onChange={handleCardOnChange}
            >
                {getCardOptions()}
            </Form.Select>
        </Fragment>
    );
}

export default CardsOnFile;