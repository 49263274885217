import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import React, {useEffect, useState} from 'react'
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Alert from "react-bootstrap/Alert"
import Col from "react-bootstrap/Col"
import AxiosInstance from '../../AxiosInstance.js'
import API_V2 from "../../API_V2";
import InputGroup from 'react-bootstrap/InputGroup'
import {useNavigate} from "react-router";
import {Logger} from "../../Utils";


const SignIn = (props) => {

    const navigate = useNavigate();
    const [showAlert, setShowAlert] = useState(false);
    const [valid, setValid] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [formData, setFormData] = useState({
        // "email": "",
        // "password": ""
    });

    useEffect(() => {
        Logger({page: 'sign-in', action: 'open'})
    }, [])

    const handleAutoLogin = () => {

        let token = localStorage.getItem('userToken');
        let access_token = localStorage.getItem('access_token');

        if (token !== null && access_token !== null) {
            AxiosInstance.get(`/checktoken/${token}`).then(res => {
                    let {data} = res;
                    if (!data["expired"]) {

                        let memberId = localStorage.getItem("memberId");

                        if (memberId === undefined ||
                            memberId === "undefined" ||
                            memberId === null ||
                            memberId.length < 5) {
                            // props.history.replace('/link-account')
                            navigate('/link-account');
                        } else {
                            // props.history.replace('/account-summary')
                            navigate('/my/overview');
                        }

                    } else {
                        localStorage.clear();
                    }

                }
            )
        }

    };

    // useEffect(handleAutoLogin, []);

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setFormData({
            ...formData,
            [name]: value
        });
    }

    const handleRedirectsOnSuccess = () => {
        const memberDataJson = JSON.parse(localStorage.getItem('memberData'));

        if (memberDataJson === null) {
            navigate('/link-account');
            return;
        }

        const today = new Date();

        let lastUpdated = memberDataJson.address.modifiedDt;
        if (lastUpdated === undefined || lastUpdated === null) {
            lastUpdated = new Date("2020-01-01")
        } else {
            lastUpdated = new Date(lastUpdated)
        }

        let diffDt = (today.getTime() - lastUpdated.getTime());

        diffDt = Math.floor(diffDt / 86400000) //days
        // diffDt = Math.floor((diffDt % 86400000) / 3600000); // hours

        if (diffDt >= 60) {
            navigate('/address-confirm');
            return;
        }

        navigate('/my/overview');
    }


    const validateEmail = (emailAddress) => {
        const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        return regex.test(emailAddress);
    }

    const validatePassword = (password) => {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d@$!%*?&]{6,}$/;
        return regex.test(password);
    }

    const handleLogin = (e) => {
        e.preventDefault();
        e.stopPropagation();

        Logger({
            page: 'sign-in',
            method: "handleLogin",
            payload: {type: "formData", data: formData}
        })

        localStorage.clear();

        // validate input
        let formValidationErrors = {};
        !validateEmail(formData.email) ? formValidationErrors.email = "Invalid email address" : delete formValidationErrors.email;
        !validatePassword(formData.password) ? formValidationErrors.password = "Minimum 6 characters, at least one uppercase letter, " +
                "one lowercase letter and one number." :
            delete formValidationErrors.password;


        // if any errors, set validation messages and do not continue
        if (Object.keys(formValidationErrors).length > 0) {

            setFormErrors({...formValidationErrors})
            return;
        }

        (async () => {

            // this is needed to submit post as x-www-form-urlencoded to token api
            const params = new URLSearchParams();
            params.append('username', formData.email);
            params.append('password', formData.password);

            // authenticate to get access token
            const access_token_data = await API_V2.post('/v2/token', params).catch(e =>
                console.log(`access_token: ${JSON.stringify(e.response.data.detail)}`)
            );

            // if token is returned, save it to localStorage for subsequent calls
            if (access_token_data) {
                localStorage.setItem('access_token', access_token_data.data.access_token);
                AxiosInstance.defaults.headers['Authorization'] = 'Bearer ' + access_token_data.data.access_token;
                API_V2.defaults.headers['Authorization'] = 'Bearer ' + access_token_data.data.access_token;
            }

            // TODO: legacy api call to deprecate
            const authRes = await AxiosInstance.post('/authenticate', {
                email: formData.email,
                password: formData.password
            });

            // if no auth response (no token), show alert and do not continue
            if (authRes.data === null || authRes.data.token == null) {
                setShowAlert(true);
                return;
            }

            //set session data
            localStorage.setItem('email', authRes.data.email);
            localStorage.setItem('userToken', authRes.data.token);
            localStorage.setItem('memberId', authRes.data.memberId);
            localStorage.setItem('id', authRes.data._id);

            //check if email is confirmed, if not redirect to the confirm email page
            if (!authRes.data.emailConfirmation.confirmed ||
                authRes.data.emailConfirmation.confirmed === false
            ) {
                navigate(`/confirm-email?email=${authRes.data.email}`);
                return;
            }

            // check if the user is a member
            if (authRes.data.memberId === undefined || authRes.data.memberId === null ||
                authRes.data.memberId.length < 5
            ) { // if not, redirect to the link account page
                navigate('/link-account');
                return;
            } else { // user is a member set additional session data
                AxiosInstance.defaults.headers['Authorization-Token'] = authRes.data.token;



            }

        })().then(()=> {
            const memberId = localStorage.getItem('memberId')

            if (memberId) {
                AxiosInstance.get('/api/v1/member/' + memberId).then((member, err)=>{

                    localStorage.setItem('memberData', JSON.stringify(member.data));
                    // show that input is valid then redirect
                    setValid(true);
                    setTimeout(() => {
                        handleRedirectsOnSuccess();
                    }, 500)
                });
            }
        })

    }


    return (

        <Container>
            <div className="row justify-content-md-center mt-5 mb-3">
                <div className="col-md-7 text-center">
                    <div className="h4 mb-1">Sign In To Your Account</div>
                </div>
            </div>
            <Row className="justify-content-md-center pb-5">
                <Col className="col-md-5 col-lg-4 align-self-center pb-5">
                    <Alert variant="danger" show={showAlert}>
                        <span className="small">Invalid username or password.</span>
                    </Alert>
                    <Form noValidate onSubmit={handleLogin} className="d-grid gap-2">
                        <Form.Group controlId="formBasicEmail">
                            <InputGroup>
                                <InputGroup.Text id="basic-addon1">@</InputGroup.Text>
                                <Form.Control name="email" required type="email"
                                              isInvalid={formErrors.email}
                                              isValid={valid}
                                              placeholder="Email Address"
                                              onChange={handleInputChange}/>
                                <Form.Control.Feedback type="invalid">{formErrors.email}</Form.Control.Feedback>
                            </InputGroup>

                        </Form.Group>

                        <Form.Group controlId="formBasicPassword">
                            <Form.Control name="password" required type="password"
                                          isInvalid={formErrors.password}
                                          isValid={valid}
                                          placeholder="Password"
                                          onChange={handleInputChange}/>
                            <Form.Control.Feedback type="invalid">{formErrors.password}</Form.Control.Feedback>
                        </Form.Group>


                        <Button variant="default" type="submit" className="mt-3">
                            Sign In
                        </Button>
                    </Form>


                    <div className="mt-3 text-muted text-center">
                        <Button variant="link danger" className="pl-1 pb-2"
                                onClick={() => navigate('/forgot-password')}>
                            Forgot Password?
                        </Button>
                    </div>
                    <div className="text-muted text-center mt-3 d-grid gap-0">
                        <span className="">Don't have an account?</span>
                        <Button variant="link" className="p-0" onClick={() => navigate('/sign-up')}>
                            Sign Up here.
                        </Button>
                    </div>
                </Col>
            </Row>

        </Container>
    );

}

export default SignIn;
