import React, {useEffect, useState} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import {useNavigate} from "react-router";
import {useLocation} from "react-router-dom";
import AxiosInstance from "../../AxiosInstance";


const CampaignCreate = (props) => {


    const {state} = useLocation();
    const [formData, setFormData] = useState({organizationNumber: state.organizationNumber});
    const [formErrors, setFormErrors] = useState({});
    const navigate = useNavigate();



    useEffect(()=> {

        if (state.campaign_id != null) {
            AxiosInstance.get(`/api/v1/campaign/${state.campaign_id}`).then(res => {
                setFormData({...res.data});
            })
        }


    }, [])




    const handleSubmit = () => {

        const asyncSubmit = async () => {

            const campaign = await AxiosInstance.post('/api/v1/campaign', formData);

            if (campaign.data && state.campaign_id == null) {

                const newCampaignRun = {
                    campaignId: campaign.data._id,
                    name: campaign.data.campaignName,
                    description: campaign.data.campaignName,
                    active: false,
                    startDt: Date.now(),
                    endDt: new Date().setDate(new Date().getDate() + 30),  // add 30 days
                    createdBy: localStorage.getItem("email"),
                    modifiedBy: localStorage.getItem("email")
                }

                const campaignRun = await AxiosInstance.post('/api/v1/campaign-run/', newCampaignRun);
            }

            return {campaignId: campaign.data._id}


        }

        asyncSubmit().then(res => {
            navigate(`/campaign/${res.campaignId}`);
        })

        // AxiosInstance.post('/api/v1/campaign', formData).then((res => {
        //
        // }))
    }

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;


        let newFormData = {
            ...formData,
            [name]: value
        }

        delete formErrors[name];
        setFormErrors({...formErrors});

        setFormData(newFormData);

    }


    return (

        <Container>
            <Row className="mt-3 mb-2">
                <Col>
                    <Button variant="link" className="p-0" onClick={() => navigate(-1)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor"
                             className="bi bi-arrow-left-circle" viewBox="0 0 16 16">
                            <path fillRule="evenodd"
                                  d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"/>
                        </svg>
                    </Button>
                </Col>
            </Row>

            <div className="h5 mt-3">Create Campaign</div>

            <Row>
                <Col md={4}>
                    <Form.Control name="campaignName" required type="input"
                                  onChange={handleInputChange}
                                  maxLength={30}
                                  value={formData.campaignName}
                                  // isInvalid={!!formErrors.cityNm}
                                  placeholder="Campaign Name"
                    />
                    {/*<Form.Control.Feedback type="invalid">{formErrors.cityNm}</Form.Control.Feedback>*/}
                </Col>
            </Row>
            <Row>
                <Col md={4}>
                    <Form.Control name="shortDescription" required type="input"
                                  onChange={handleInputChange}
                                  as="textarea" rows={3}
                                  // maxLength={30}
                                  value={formData.shortDescription}
                        // isInvalid={!!formErrors.cityNm}
                                  placeholder="Short Description"
                    />
                    {/*<Form.Control.Feedback type="invalid">{formErrors.cityNm}</Form.Control.Feedback>*/}
                </Col>

            </Row>
            <Row>
                <Col md={4}>
                    <Form.Check name="requiresMembership" required type="checkbox"
                                  onChange={handleInputChange}
                                  label="Requires Membership"
                        // maxLength={30}
                                  checked={formData.requiresMembership}
                        // isInvalid={!!formErrors.cityNm}
                                  placeholder="Short Description"
                    />
                    {/*<Form.Control.Feedback type="invalid">{formErrors.cityNm}</Form.Control.Feedback>*/}
                </Col>

            </Row>

            <Row className="mt-3">

                <Col md={4} className="d-grid">
                    <Button variant="default" className="d-block" onClick={handleSubmit} >Create
                        {/*{organization.organizationNumber ? "Update" : "Create"}*/}
                    </Button>
                </Col>
            </Row>

        </Container>

    );

}

// CampaignCreate.defaultProps = {
//
// }

export default CampaignCreate;