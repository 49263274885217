import React, {useEffect, useState} from 'react'
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row"
import AxiosInstance from "../../AxiosInstance";
import {Spinner} from "react-bootstrap";
import AppConfig from "../../Config/AppConfig";
import ThankYouForPayment from "./ThankYouForPayment";
import {useNavigate} from "react-router";
import CreditCard from "../../UIComponents/Payment/CreditCard";


const CreditCardInfo = (props) => {

    const CHARGE_PROCESSING_FEE = true
    const CARD_PERCENT_FEE = 0.0125
    const PROCESSING_FEE = 10
    const BANK_FEE = 38


    const navigate = useNavigate();

    const [isLoaded] = useState(false)
    const [processing, setProcessing] = useState(false)
    const [success, setSuccess] = useState(false);
    const [total, setTotal] = useState(0)
    const [donationSummary, setDonationSummary] = useState([])
    const [processingFee, setProcessingFee] = useState(0);
    const [payee, setPayee] = useState("");

    const [valid] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [formData, setFormData] = useState({
        cardholderName: "",
        cardNumber: null,
        expMM: "01",
        expYY: new Date().getFullYear(),
        CCV: null
    });

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setProcessing(false);

        setFormData({
            ...formData,
            [name]: value
        });

    }

    const ccYearsOptions = (start) => {
        const range = [...Array(12).keys()];

        return range.map((number, i) =>
            <option key={start + i} value={start + i}>{start + i}</option>
        );
    };

    const ccMonthsOptions = () => {
        const range = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

        return range.map((month, idx) => {
                let i = idx < 9 ? '0' + (idx + 1) : idx + 1;
                return <option key={i} value={i}>{i + '-' + month}</option>
            }
        );
    };

    const calculateFee = (amount) => {

        if (CHARGE_PROCESSING_FEE) {
            return Number(Math.round(((amount / 100) * CARD_PERCENT_FEE + ((PROCESSING_FEE + BANK_FEE) / 100)) + 'e2') + 'e-2')
        } else {
            return 0;
        }
    }

    const handleCancel = () => {
        props.history.push('/account-summary');
    };

    const handlePay = (e) => {

        e.preventDefault();
        e.stopPropagation();

        // cardNumber = "6011361000006668"
        // expMonth = 12
        // expYear = 2020
        // CVV = None

        AxiosInstance.defaults.headers['Authorization-Token'] = localStorage.getItem('userToken');
        AxiosInstance.defaults.timeout = 50000

        let errors = {};

        !/[a-z0-9\w]{2,}$/i.test(formData.cardholderName) ? errors.cardholderName = "cardholderName name is required" :
            delete errors.cardholderName;

        !/^[0-9]{10,}$/i.test(formData.cardNumber) ? errors.cardNumber = "Invalid card number" :
            delete errors.cardNumber;

        let lineItems = [];

        donationSummary.forEach(ds => {
            lineItems.push({
                name: `Funeral Fund Membership Fee (${ds.externalRef})`,
                price: ds.minAmountDue * 100
            })
        });

        /*
        Employee ID:
        Sandbox: ZZRWMZ80A6HPC
        Production: TQEG2VXFSD2CJ
         */


        let paymentInfo = {
            "memberName": payee,
            "memberId": localStorage.getItem("memberId"),
            "externalRef": donationSummary.length > 1 ? 'Multiple' : donationSummary[0].externalRef,
            "employee": AppConfig().clover.employee,
            "testMode": true,
            "orderTotal": total * 100,
            "processingFee": processingFee * 100,
            "lineItems": lineItems,
            "cardholderName": formData.cardholderName,
            "card": {
                cardNumber: formData.cardNumber,
                expMonth: formData.expMM,
                expYear: formData.expYY,
                CVV: formData.CCV
            }
        }

        if (Object.keys(errors).length === 0) {
            console.log(paymentInfo)
            setProcessing(true);
            AxiosInstance.post('/api/v1/donation/pay/', paymentInfo).then(payRes => {
                let {data} = payRes;

                if (data.result === "APPROVED") {
                    setSuccess(true);
                } else if (data.result === "DECLINED") {
                    errors.cardNumber = "Your card was declined. Please verify that you entered it " +
                        "correctly or try a different card.";
                    setProcessing(false);
                } else if (data.message === "Last 4 card numbers are invalid.") {
                    errors.cardNumber = data.message;
                    setProcessing(false);
                } else if (data.message === "First 6 card numbers are invalid.") {
                    errors.cardNumber = data.message;
                    setProcessing(false);
                } else if (data === "Error connecting to Payment Gateway") {
                    errors.cardNumber = data;
                    setProcessing(false);
                } else {
                    errors.cardNumber = data.message;
                    setProcessing(false);
                }

                setFormErrors({...errors})

                console.log("Error!: " + data)

            }).catch(err => {
                console.log("error:")
                console.log(JSON.stringify(err));
                setProcessing(false);
            })

        }




        setFormErrors({...errors})

    };


    useEffect(() => {

            // setDonationSummary([...props.location.state.summary]);
            // setPayee(props.location.state.payee)
            // const fee = calculateFee(props.location.state.totals.totalPaymentDue * 100);
            // const totalDue = props.location.state.totals.totalPaymentDue;
            // setProcessingFee(fee);
            // setTotal(totalDue + fee);
        }, [isLoaded]
    )


    let donationDetailsList = donationSummary.map(m =>
        <div key={m.externalRef}>
            <div className="row small">
                <div className="col-5 p-0 text-right">Funeral Fund Fee</div>
                <div className="col-3 p-0 text-right">{m.externalRef}</div>
                <div className="col-4 pl-0 text-right">${m.minAmountDue.toFixed(2)}</div>
            </div>
        </div>);

    const summary = () => {
        return (
            <React.Fragment>
                <div className="row small">
                    <div className="col-5 p-0 text-right font-weight-bolder">
                        Description
                    </div>
                    <div className="col-3 p-0 text-right font-weight-bolder">
                        Ref
                    </div>
                    <div className="col-4 pl-0 text-right font-weight-bolder">
                        Amount
                    </div>
                </div>
                <div>
                    {donationDetailsList}
                </div>
                <div className="row small">
                    <div className="col-5 p-0 text-right">
                        Processing Fee
                    </div>
                    <div className="col-3 p-0 text-right">

                    </div>
                    <div className="col-4 pl-0 text-right">
                        ${processingFee}
                    </div>

                </div>
                <div className="row font-weight-bolder small">
                    <div className="col-5 p-0 text-right">
                        Total
                    </div>
                    <div className="col-3 p-0 text-right">

                    </div>
                    <div className="col-4 pl-0 text-right">
                        ${total}
                    </div>
                </div>
            </React.Fragment>
        )
    }


    const thanksForPaymentLayout = <ThankYouForPayment paymentAmount={total} />


    let paymentInformationLayout =
        <div className="container">
            <div className="pb-5">
                <div className="row justify-content-md-center mt-4">
                    <div className="col-md-7 text-center">
                        <div className="h4 mb-1">Payment Information</div>
                    </div>
                </div>
                <div className="row justify-content-md-center mt-2 mb-5">
                    <Col className="col-12 col-md-6 col-lg-5 align-self-center">
                        <Card>
                            <Card.Header>Summary</Card.Header>
                            <Card.Body>
                                <div className="mb-3">
                                    {summary()}
                                </div>
                                <Button variant="default"
                                        disabled={processing}
                                        onClick={() => navigate(-1)}>
                                    Cancel
                                </Button>
                            </Card.Body>
                        </Card>
                        <Card className="mt-3">
                            <Card.Header>Payment Card Information</Card.Header>
                            <Card.Body>
                                <Form>

                                    <Form.Group controlId="formNameOnCard">
                                        <InputGroup>

                                            <span className="input-group-text">
                                                    <svg className="bi bi-person-fill" width="20" height="20"
                                                         viewBox="0 0 20 20"
                                                         fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd"
                                                          d="M5 16s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H5zm5-6a3 3 0 100-6 3 3 0 000 6z"
                                                          clipRule="evenodd"/>
                                                    </svg>
                                            </span>

                                            <Form.Control name="cardholderName" required type="input"
                                                          isInvalid={formErrors.cardholderName}
                                                          isValid={valid}
                                                          onChange={handleInputChange}
                                                          placeholder="Name as it appears on the card"/>
                                            <Form.Control.Feedback
                                                type="invalid">{formErrors.cardholderName}</Form.Control.Feedback>

                                        </InputGroup>

                                    </Form.Group>

                                    <Form.Group controlId="formCardNumber">
                                        <InputGroup>

                                        <span className="input-group-text">
                                                <svg className="bi bi-credit-card" width="20" height="20"
                                                     viewBox="0 0 20 20"
                                                     fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd"
                                                          d="M16 5H4a1 1 0 00-1 1v8a1 1 0 001 1h12a1 1 0 001-1V6a1 1 0 00-1-1zM4 4a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2H4z"
                                                          clipRule="evenodd"/>
                                                    <rect width="3" height="3" x="4" y="11" rx="1"/>
                                                    <path d="M3 7h14v2H3z"/>
                                                </svg>
                                        </span>

                                            <Form.Control name="cardNumber" required type="input"
                                                          isInvalid={formErrors.cardNumber}
                                                          isValid={valid}
                                                          onChange={handleInputChange}
                                                          placeholder="Your card number"/>
                                            <Form.Control.Feedback
                                                type="invalid">{formErrors.cardNumber}</Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>

                                    <Form.Group>
                                        <Row>
                                            <Col className="col-8">
                                                <InputGroup>
                                                    <Form.Control name="expMM" className="mr-2" as="select" size="sm"
                                                                  isInvalid={formErrors.expMM}
                                                                  isValid={valid}
                                                                  onChange={handleInputChange}
                                                                  placeholder="MM"
                                                                  required>
                                                        {ccMonthsOptions()}
                                                    </Form.Control>
                                                    <Form.Control name="expYY" className="" as="select" size="sm"
                                                                  isInvalid={formErrors.expYY}
                                                                  isValid={valid}
                                                                  onChange={handleInputChange}
                                                                  placeholder="YY"
                                                                  required>
                                                        {ccYearsOptions(new Date().getFullYear())}
                                                    </Form.Control>
                                                </InputGroup>
                                            </Col>
                                            <Col className="col-1 p-0"/>
                                            <Col className="col-3 text-right justify-content-end">
                                                <Form.Control name="CCV"
                                                              isInvalid={formErrors.CCV}
                                                              isValid={valid}
                                                              onChange={handleInputChange}
                                                              required type="number"
                                                              size="sm"
                                                              maxLength="5"
                                                              placeholder="CCV"/>
                                            </Col>
                                        </Row>

                                    </Form.Group>
                                    <Button variant={processing? "secondary":"success"}
                                            type="submit"
                                            onClick={handlePay} >
                                        <div className={!processing? "d-none": "d-block text-center"}>
                                            <Spinner animation="border" size="sm" variant="light" className="mr-2" />
                                            Processing
                                        </div>
                                        <div className={processing? "d-none": "d-block text-center"}>
                                            Pay $ {total}
                                        </div>


                                    </Button>
                                </Form>
                            </Card.Body>
                        </Card>

                    </Col>



                    <CreditCard />

                </div>
            </div>
        </div>


    return (
        success ? thanksForPaymentLayout : paymentInformationLayout
    );
};

export default CreditCardInfo;