import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import React, {useEffect, useState} from "react";
import {Modal, ModalFooter} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import AxiosInstance from "../../AxiosInstance";


const MemberHouseholdAdd = (props) => {


    const formInitialState = {
        firstNm: "",
        lastNm: "",
        relationship: "-1",
    }


    const [show, setShow] = useState(false);
    const [formData, setFormData] = useState(formInitialState);
    const [formErrors, setFormErrors] = useState({});


    useEffect(()=>{

        setShow(props.show)

        return(() => {
            setFormData(formInitialState);
            setFormErrors({});
        })

    }, [props.show])


    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setFormData({
            ...formData,
            [name]: value
        });

        //reset error message if exists
        let errors = formErrors;
        delete errors[name];
        setFormErrors({...errors});

    }

    const handleDateInputChange = (event) => {
        const target = event.target;
        const value = target.value.replace(/[^0-9]/g, '');
        const name = target.name;

        let temp_val = value;

        if (value.length > 4) {
            temp_val = temp_val.substr(0, 2) + '/' +
                temp_val.substr(2, 2) + '/' +
                temp_val.substr(4)
        } else if (value.length > 2) {
            temp_val = temp_val.substr(0, 2) + '/' +
                temp_val.substr(2)
        }
        ;

        setFormData({
            ...formData,
            [name]: temp_val
        });
        target.value = temp_val;

        //reset error message if exists
        let errors = formErrors;
        delete errors[name];
        setFormErrors({...errors});

    }

    const handleClose = () => {
        setShow(false);
        if (props.onClosed) {
            props.onClosed();
        }

    }

    const handleUpdate = () => {


        let errors = {};

        console.log("formData.firstNm: " + formData.firstNm);

        !/[a-z0-9\w]{2,}$/i.test(formData.firstNm) ? errors.firstNm = "Invalid First Name" :
            delete errors.firstNm;

        !/([A-Z0-9]){2,}$/i.test(formData.lastNm) ? errors.lastNm = "Invalid Last Name" :
            delete errors.lastNm;

        !/^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/.test(formData.birthDt) ? errors.birthDt = "Invalid Birth Date" :
            delete errors.birthDt;

        formData.relationship == "-1" ? errors.relationship = "Select Relationship" :
            delete errors.relationship;

        setFormErrors({...errors});

        if (Object.keys(errors).length === 0) {

            let payload = {
                memberId: formData.memberId,
                lastNm: formData.lastNm,
                firstNm: formData.firstNm,
                middleInit: formData.middleInit,
                birthDt: formData.birthDt,
                organizationNumber: props.organizationNumber,
                household_id: props.household_id,
                relationship: formData.relationship,
                status: "PENDING",
                modifiedBy: localStorage.getItem("email"),
                modifiedDt: Date.now()
            }

            if (formData.memberId) {
                payload.createdBy = localStorage.getItem("email");
                payload.createdDt = Date.now();
            }

            console.log("payload: " + JSON.stringify(payload));

            AxiosInstance.post('/api/v1/member', payload).then((res => {
                setShow(false);
                if (props.onSubmitted) {
                    props.onSubmitted()
                }
            }));

        }

    }

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header className="grid-header" closeButton>
                <Modal.Title>New Household Member</Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-grid gap-2">
                <Row>
                    <Col>
                        First Name<span className="text-danger">*</span>
                    </Col>
                    <Col>
                        <Form.Control name="firstNm" required type="input"
                                      maxLength={100}
                                      value={formData.firstNm}
                                      onChange={handleInputChange}
                                      isInvalid={!!formErrors.firstNm}
                                      placeholder="First Name"
                        />
                        <Form.Control.Feedback type="invalid">{formErrors.firstNm}</Form.Control.Feedback>
                    </Col>

                </Row>
                <Row>
                    <Col>
                        Last Name<span className="text-danger">*</span>
                    </Col>
                    <Col>
                        <Form.Control name="lastNm" required type="input"
                                      maxLength={100}
                                      value={formData.lastNm}
                                      onChange={handleInputChange}
                                      isInvalid={!!formErrors.lastNm}
                                      placeholder="Last Name"
                        />
                        <Form.Control.Feedback type="invalid">{formErrors.lastNm}</Form.Control.Feedback>
                    </Col>
                </Row>
                <Row className="flex-row">
                    <Col>
                        Middle Initial
                    </Col>
                    <Col className="justify-content-center">
                        <div className="col-3">
                            <Form.Control name="middleInit" required type="input"
                                          maxLength={1}
                                          value={formData.middleInit}
                                          onChange={handleInputChange}
                                          placeholder="MI"
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        Birth Date<span className="text-danger">*</span>
                    </Col>
                    <Col>
                        <Form.Control name="birthDt" required type="input"
                                      maxLength="10"
                                      value={formData.birthDt}
                                      isInvalid={!!formErrors.birthDt}
                                      onChange={handleDateInputChange}
                                      placeholder="MM/DD/YYYY"
                                      autoComplete="disabled"/>
                        <Form.Control.Feedback type="invalid">{formErrors.birthDt}</Form.Control.Feedback>
                    </Col>
                </Row>
                <Row>
                    <Col>Relationship<span className="text-danger">*</span></Col>
                    <Col>
                        <Form.Select name="relationship"
                                     value={formData.relationship}
                                     isInvalid={!!formErrors.relationship}
                                     onChange={handleInputChange}>
                            <option value="-1" selected={true}>Relationship</option>
                            {/*<option value="Spouse">Spouse</option>*/}
                            <option value="Son">Son</option>
                            <option value="Daughter">Daughter</option>
                            {/*<option value="Parent">Parent</option>*/}
                            {/*<option value="Son-In-Law">Son-In-Law</option>*/}
                            {/*<option value="Daughter-In-Law">Daughter-In-Law</option>*/}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">{formErrors.relationship}</Form.Control.Feedback>
                    </Col>
                </Row>
            </Modal.Body>
            <ModalFooter>
                <Button className="btn-default"
                        onClick={handleUpdate}
                >
                    Submit Request
                </Button>
            </ModalFooter>
        </Modal>
    );

}

export default MemberHouseholdAdd;