import {Fragment, useEffect, useState} from "react";
import Container from "react-bootstrap/Container";


const PhoneView = (props) => {

    const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);
    const [phoneNumber, setPhoneNumber] = useState("");

    const parseAndVerifyPhoneNumber = () => {
        if (isValidPhoneNumber(props.phoneNumber)) {
            setPhoneNumber(formatPhoneNumber(props.phoneNumber));
            setIsPhoneNumberValid(true);
        } else if (props.phoneNumber?.toString().length > 1) {  // display alert only when value is present
            setPhoneNumber(props.phoneNumber);
            setIsPhoneNumberValid(false);
        } else {
            setIsPhoneNumberValid(true); // do not show message if phone is null or not present
        }
    }

    useEffect(parseAndVerifyPhoneNumber, [props.phoneNumber])

    const isValidPhoneNumber = (phoneNumber) => {
        // Check if the input string is a valid 10 digit US phone number
        let phoneNumberString = phoneNumber ? phoneNumber.toString() : "";
        phoneNumberString = phoneNumberString.replace(/[^0-9]/gi, '');
        return phoneNumberString.length === 10
    }

    const formatPhoneNumber = (phoneNbr) => {
        // convert to string
        let phoneNumberString = phoneNbr ? phoneNbr.toString() : "";
        //remove non-number characters
        phoneNumberString = phoneNumberString.replace(/[^0-9]/gi, '');
        if (phoneNumberString.length === 10) {
            return `(${phoneNumberString.substring(0, 3)}) ${phoneNumberString.substring(3, 6)} - ${phoneNumberString.substring(6, 10)}`;
        }
        return "" // to minimize undefined/null errors
    }

    const alertIcon = !isPhoneNumberValid ?
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="red"
             className="bi bi-info-circle" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
            <path
                d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l-.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
        </svg> : null;

    return (
        <Fragment>
            <span className={isPhoneNumberValid ? null: "text-danger"}>{alertIcon}{` `}{phoneNumber}</span>
        </Fragment>
    )
}
PhoneView.defaultProps = {
    phoneNumber: "0000000000"
}
export default PhoneView;