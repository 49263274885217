import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React, {Fragment, useEffect, useState} from "react";


const AddressEdit = (props) => {


    const [address, setAddress] = useState(props.address);
    const [formErrors, setFormErrors] = useState({});

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        let newAddress = {
            ...address,
            [name]: value
        }

        delete formErrors[name];
        setFormErrors({...formErrors});

        setAddress(newAddress);

        if (props.onChange) {
            props.onChange(newAddress);
        }

    }

    useEffect(() => {

        if (props.address === null) {
            setAddress({})
        } else {
            setAddress(props.address);
        }
        return (()=> {
            setAddress({});
        })
    }, [props.address])

    useEffect(() => {
        if (props.validate) {
            handleValidate();
        }
    }, [props.validate])

    const handleValidate = () => {

        let errors = {};

        !/[a-z0-9\w\s]{4,}$/i.test(address.streetAddress) ? errors.streetAddress = "Street Name and Number is required" :
            delete errors.streetAddress;

        !/([A-Z0-9]){4,}$/i.test(address.cityNm) ? errors.cityNm = "City name is required" :
            delete errors.cityNm;

        !/([A-Z0-9]){2,}$/i.test(address.stateCd) ? errors.stateCd = "State is required" :
            delete errors.stateCd;

        !/([A-Z0-9]){4,}$/i.test(address.postalCd) ? errors.postalCd = "Zip Code is required" :
            delete errors.postalCd;

        if (Object.keys(errors).length === 0) {
            if (props.onValidated) {
                props.onValidated(true);
            }
        }

        setFormErrors({...errors})


    }


    return (
        <Fragment>
            {/*<Alert variant={alert.variant} show={showAlert}>*/}
            {/*    {alert.message}*/}
            {/*</Alert>*/}
            <Row className="mb-3">
                <Col>
                    <Form.Control name="streetAddress" required type="input"
                                  maxLength={100}
                                  value={address.streetAddress}
                                  onChange={handleInputChange}
                                  isInvalid={!!formErrors.streetAddress}
                                  placeholder="House number and street name"
                    />
                    <Form.Control.Feedback type="invalid" className="small">{formErrors.streetAddress}</Form.Control.Feedback>
                </Col>
            </Row>
            <Row style={{'--bs-gutter-x': '0.4rem'}}>
                <Col md={7} xs={5}>
                    <Form.Control name="cityNm" required type="input"
                                  onChange={handleInputChange}
                                  maxLength={30}
                                  value={address.cityNm}
                                  isInvalid={!!formErrors.cityNm}
                                  placeholder="City"
                    />
                    <Form.Control.Feedback type="invalid">{formErrors.cityNm}</Form.Control.Feedback>
                </Col>
                <Col md={2} xs={3}>
                    <Form.Control name="stateCd" required type="input"
                                  value={address.stateCd}
                                  maxLength={2}
                                  onChange={handleInputChange}
                                  isInvalid={!!formErrors.stateCd}
                                  onKeyPress={(e) => !/[a-zA-Z]/.test(e.key) && e.preventDefault()}
                                  placeholder="State"
                    />
                </Col>
                <Col md={3} xs={4}>
                    <Form.Control name="postalCd" required type="input"
                                  value={address.postalCd}
                                  maxLength={5}
                                  onChange={handleInputChange}
                                  isInvalid={!!formErrors.postalCd}
                                  onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                  placeholder="Zip Code"
                    />
                </Col>
            </Row>
        </Fragment>
    )
}

AddressEdit.defaultProps = {
    address: {
        streetAddress: "",
        cityNm: "",
        stateCd: "",
        postalCd: ""
    },
    errors: {
        streetAddress: "",
        cityNm: "",
        stateCd: "",
        postalCd: ""
    }
}

export default AddressEdit;