import Form from "react-bootstrap/Form";
import React, {Fragment, useEffect, useState} from "react";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import AxiosInstance from "../../AxiosInstance";
import Button from "react-bootstrap/Button";

const IntakeForm = (props) => {


    const [formErrors, setFormErrors] = useState({});
    const [formData, setFormData] = useState({});

    let controls = [
        {type: "input", format:"string", required: true, name:"firstNm", length: 100, placeholder:'First Name', default:"", label: 'First Name', validationRegEx: "[a-z0-9\\w]{2,}$"},
        {type: "input", format:"string", required: true, name:"lastNm", length: 100, placeholder:'Last Name', default:"", label: 'Last Name', validationRegEx: "[a-z0-9\\w]{2,}$"},
        {type: "input", format:"string", name:"midInit", length: 1, placeholder:'MI', label: 'Middle Initial', validationRegEx: "[a-z0-9\\w]{1,}$"},
        {type: "input", format:"date", required: true, name:"birthDt", length: 10, placeholder:'MM/DD/YYYY', label: 'Birth Date', validationRegEx: "^(0[1-9]|1[0-2])\\/(0[1-9]|1\\d|2\\d|3[01])\\/(19|20)\\d{2}$"},
        // {type: "dropdown", required: true, default:'Relationship', label: 'Relationship'},
        // {type: "radio"},
        // {type: "text"},
    ]




    useEffect(() => {
        let initialFormData = {}

        controls.forEach(c => {
            initialFormData[c.name] = c.default ? c.default : ""
        })
        setFormData(initialFormData)

    }, []);


    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setFormData({
            ...formData,
            [name]: value
        });

        //reset error message if exists
        let errors = formErrors;
        delete errors[name];
        setFormErrors({...errors});

    }

    const handleDateInputChange = (event) => {
        const target = event.target;
        const value = target.value.replace(/[^0-9]/g, '');
        const name = target.name;

        let temp_val = value;

        if (value.length > 4) {
            temp_val = temp_val.substr(0, 2) + '/' +
                temp_val.substr(2, 2) + '/' +
                temp_val.substr(4)
        } else if (value.length > 2) {
            temp_val = temp_val.substr(0, 2) + '/' +
                temp_val.substr(2)
        }
        ;

        setFormData({
            ...formData,
            [name]: temp_val
        });
        target.value = temp_val;

        //reset error message if exists
        let errors = formErrors;
        delete errors[name];
        setFormErrors({...errors});

    }


    const handleUpdate = () => {


        let errors = {};



        controls.forEach(c => {
            if (c.validationRegEx !== undefined) {
                let regex = new RegExp(c.validationRegEx, 'i');
                !regex.test(formData[c.name]) ? errors[c.name] = "Invalid " + c.label :
                    delete errors[c.name];
            }

        })

        // !/[a-z0-9\w]{2,}$/i.test(formData.firstNm) ? errors.firstNm = "Invalid First Name" :
        //     delete errors.firstNm;

        // !/([A-Z0-9]){2,}$/i.test(formData.lastNm) ? errors.lastNm = "Invalid Last Name" :
        //     delete errors.lastNm;
        //
        // !/^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/.test(formData.birthDt) ? errors.birthDt = "Invalid Birth Date" :
        //     delete errors.birthDt;
        //
        // formData.relationship == "-1" ? errors.relationship = "Select Relationship" :
        //     delete errors.relationship;

        setFormErrors({...errors});

        if (Object.keys(errors).length === 0) {

            let payload = {
                // memberId: formData.memberId,
                // lastNm: formData.lastNm,
                // firstNm: formData.firstNm,
                // middleInit: formData.middleInit,
                // birthDt: formData.birthDt,
                // organizationNumber: props.organizationNumber,
                // household_id: props.household_id,
                // relationship: formData.relationship,
                // status: "PENDING",
                // modifiedBy: localStorage.getItem("email"),
                // modifiedDt: Date.now()
            }

            // if (formData.memberId) {
            //     payload.createdBy = localStorage.getItem("email");
            //     payload.createdDt = Date.now();
            // }


            // AxiosInstance.post('/api/v1/member', payload).then((res => {
            //     setShow(false);
            //     if (props.onSubmitted) {
            //         props.onSubmitted()
            //     }
            // })
            // );

        }

    }


    const renderControl = (control) => {

        if (control.type == "input") {
            let handleChange;

            if (control.format == "date") {
                handleChange = handleDateInputChange;
            } else {
                handleChange = handleInputChange;
            }

            return (
                <Row className="pt-1">
                    <Col>
                        {/*<label style={{*/}
                        {/*            marginLeft: "15px",*/}
                        {/*            marginTop: "-14px",*/}
                        {/*            padding: "0 2px 0 5px",*/}
                        {/*            position: "absolute",*/}
                        {/*            backgroundColor: "white",*/}
                        {/*            color: "#03045e",*/}
                        {/*            border: "1px solid white"}}*/}
                        {/*       className="small"*/}
                        {/*>*/}
                        <label className="label-embedded">
                            {control.label}{control.required? <span className="text-danger">*</span>: null}
                        </label>
                        <Form.Control name={control.name} required type="input"
                                      className="form- mt-n3"
                                      maxLength={control.length}
                                      value={formData[control.name]}
                                      onChange={handleChange}
                                      isInvalid={!!formErrors[control.name]}
                                      placeholder={control.placeholder}
                        />
                        <Form.Control.Feedback type="invalid">{formErrors[control.name]}</Form.Control.Feedback>
                    </Col>

                </Row>

            )
        } else {
            return null;
        }


    }

    return (
        <Container className="d-grid gap-3 mt-5">
            {controls.map((c, idx) => (
                renderControl(c)
            ))
            }
            <div className="mt-5">
                <Button className="btn-default float-end"
                        onClick={handleUpdate}
                >
                    Submit Request
                </Button>
            </div>
            {/*<div className="mt-5">*/}
            {/*    <div>Form</div>*/}
            {/*    <div>{JSON.stringify(formData)}</div>*/}
            {/*</div>*/}
            {/*<div className="mt-5">*/}
            {/*    <div>Errors</div>*/}
            {/*    <div>{JSON.stringify(formErrors)}</div>*/}
            {/*</div>*/}
        </Container>
        )


}

export default IntakeForm;