import {useEffect, useState} from "react";
import {useNavigate} from "react-router";
import {Modal} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import AxiosInstance from "../../AxiosInstance";


const CampaignAddOrganization = (props) => {


    const [formData, setFormData] = useState({});
    const [organizations, setOrganizations] = useState([]);
    const [formErrors, setFormErrors] = useState({});
    const navigate = useNavigate();
    const [showDialog, setShowDialog] = useState(props.show);


    useEffect(() => {
        AxiosInstance.get('/api/v1/organization').then((res => {
            setOrganizations(res.data);
        }))
        setShowDialog(props.show);
    }, [props.show]);

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setFormData({
            ...formData,
            [name]: value
        });

    }

    const handleMemberAdd = (add_organization) => {

        (async () => {
            const campaignRes = await AxiosInstance.get(`/api/v1/campaign/` + props.campaign_id);

            let campaign = campaignRes.data;


            let existsAt = campaign.participatingOrganizations.indexOf(formData.organization);

            if (existsAt == -1) {
                campaign.participatingOrganizations.push(formData.organization);

                await AxiosInstance.post(`/api/v1/campaign/`,campaign);

            }

            console.log("organization: " + JSON.stringify(formData.organization));
            console.log("campaignRes: " + JSON.stringify(campaignRes.data));

            setShowDialog(false);
            props.onAdded();

        })();

        // const memberHouseholdPayload = {
        //     member_id: add_member_id,
        //     household_id: props.household_id,
        //     relationship: formData.relationship
        // }
        //
        // AxiosInstance.post(`/api/v1/member/${add_member_id}/household/add`, memberHouseholdPayload).then((res => {
        //     setShow(false);
        //     props.onAdded();
        // }));
    }


    return (
        <Modal show={showDialog} onHide={() => setShowDialog(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Add Participating Organization</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <Form className="d-grid gap-2">
                    <Row>
                        <Col md={4}></Col>
                        <Col md={5} className="fw-bold">
                            {formData.lastNm != null?
                                <span>
                                        <i className="bi bi-check text-success"></i>
                                    {formData.firstNm + ' ' + formData.lastNm}
                                    </span> : null
                            }

                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>Organizations</Col>
                        <Col md={7}>
                            <Form.Select name="organization"
                                         size="sm"
                                         onChange={handleInputChange}
                            >
                                <option>---</option>
                                {
                                    organizations.map((org,idx) => (
                                        <option key={idx} value={org.organizationNumber}>{org.organizationName}</option>
                                    ))
                                }
                            </Form.Select>
                        </Col>
                    </Row>
                </Form>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary"
                        onClick={() => handleMemberAdd(formData.organization)}
                        size="sm"
                        disabled={formData.organization != null? false:true}
                        className="">
                    Add
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default CampaignAddOrganization;