import React, {useState} from 'react'
import Navbar from 'react-bootstrap/Navbar'
import ActivityWatcher from "../ActivityWatcher";

import Container from "react-bootstrap/Container";
import Offcanvas from "react-bootstrap/Offcanvas";
import Nav from "react-bootstrap/Nav";
import {useNavigate} from "react-router";
import Button from "react-bootstrap/Button";
import AppConfig from "../Config/AppConfig";


const Header = (props) => {

    const navigate = useNavigate();
    let username = localStorage.getItem("email");
    let isLoggedIn = !!localStorage.getItem('access_token');
    const memberData = localStorage.getItem("memberData");
    let initials = null;
    let [showModal, setShowModal] = useState(false);

    if (memberData !== null) {
        const memberDataJson = JSON.parse(memberData);
        username = memberDataJson.firstNm + " " + memberDataJson.lastNm;
    }


    const handleExpiredSessionModalClose = () => {
        setShowModal(false);
    }

    const handleExpiredSessionContinue = () => {
        setShowModal(false);
        // aw.activityWatcher();
    }

    const handleLogout = () => {
        localStorage.clear();
        navigate('/sign-in');
    };

    const handleNavigateAddressUpdate = () => {
        navigate('address-update');
    };


    const logNav = !isLoggedIn ?
        <Button variant="link"
                onClick={()=> navigate("/sign-in")}
                className="text-light text-decoration-none"
        >
            Sign In
        </Button> :
        <Navbar.Toggle className="navbar-toggler-custom" />
    ;

    let activityMonitor = !isLoggedIn ? null :
        <ActivityWatcher onLogout={handleLogout} isLoggedIn={isLoggedIn}/>
    ;

    return (
    <div>
        {(AppConfig().global.environment === 'dev') ?
            <div className="text-light w-100 m-0 p-0 text-center" style={{background: "#f94b11"}}>
                <i className="bi bi-exclamation-triangle-fill px-1 text-warning"></i>
                <span>This is not a production version</span>
            </div> : null
        }

        <Navbar key={false} bg="bg-dark-blue"
            collapseOnSelect
            className="bg-dark-blue navbar-dark px-md-5 px-sm-0" expand={false}>
            {activityMonitor}
            <Container fluid>
                <Navbar.Brand href={isLoggedIn ? "/my/overview" : "/"}
                              className="pl-3 p-0">
                    <div>
                        <img src={process.env.PUBLIC_URL + "/images/MFC_Logo.png"} height="50px"/>
                    </div>
                </Navbar.Brand>
                {logNav} {/*menu toggle is visible when logged in*/}
                <Navbar.Offcanvas
                    id="offcanvasNavbar-expand-lg"
                    placement="end"

                >
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title id="offcanvasNavbarLabel-expand-lg">
                            Options

                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Nav className="justify-content-end flex-grow-1 pe-3">
                            {memberData ?
                                <Nav.Link href="#" onClick={() => navigate('/my/profile')}>My Profile</Nav.Link> :
                                null
                            }

                            <hr/>
                            <Nav.Link href="#" onClick={handleLogout}>Logout</Nav.Link>

                        </Nav>
                    </Offcanvas.Body>
                </Navbar.Offcanvas>

            </Container>
        </Navbar>
        </div>

    )
};

export default Header;