import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router";
import AxiosInstance from "../../AxiosInstance";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import {Link} from "react-router-dom";
import {Dropdown} from "react-bootstrap";
import {AgeCalculator, CustomToggle} from "../../Utils";
import HouseholdAddExisting from "../Household/HouseholdAddExisting";
import HouseholdLink from "../Household/HouseholdLink";


const MemberLinkedAccounts = (props) => {


    const [linkedAccounts, setLinkedAccounts] = useState([]);
    const [showAddExisting, setShowAddExisting] = useState(false);
    const [showLinkHousehold, setShowLinkHousehold] = useState(false);
    // const [formErrors, setFormErrors] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
            if (props.id != null) {
                getHouseholdSorted().then(linkedAccounts => {
                    setLinkedAccounts(linkedAccounts);
                });
            }
        }, [props.id]
    );

    const getHouseholdSorted = async () => {

        let linkedAccounts  = await AxiosInstance.get(`/api/v1/member/${props.id}/household/linked`);

        if (linkedAccounts !== undefined && linkedAccounts !== null) {
            linkedAccounts.data.sort((a,b) => {

                if (a.primaryMemberName > b.primaryMemberName) {
                    return -1;
                } else {
                    return 0;
                }

            } );
        }

        return linkedAccounts.data;
    }


    const handleUnlinkAccount = (from_member_id, unlink_household_id) => {

        AxiosInstance.get(`/api/v1/member/${from_member_id}`).then((memberRes => {

            const existsAt = memberRes.data.linkedHouseholds.indexOf(unlink_household_id);

            if (existsAt !== -1){
                memberRes.data.linkedHouseholds.splice(existsAt,1);
                AxiosInstance.post(`/api/v1/member/`, memberRes.data).then(res => {

                    getHouseholdSorted().then(linkedAccounts => {
                        setLinkedAccounts(linkedAccounts);
                    })
                })
            }

        }));

        // AxiosInstance.get(`/api/v1/member/${remove_member_id}/household/${linkedAccounts._id}/remove`).then((res => {
        //     getHouseholdSorted().then(household => {
        //         setLinkedAccounts(household);
        //     });
        // }));
    }

    const handleHouseholdExistingMemberAdded = () => {
        setShowAddExisting(false);

        if (props.id != null) {
            getHouseholdSorted().then(household => {
                setLinkedAccounts(household);
            });
        }
    }

    const handleMakePrimary = (primary_member_id) => {

        const memberHouseholdPayload = {
            member_id: primary_member_id,
            household_id: linkedAccounts._id,
            isPrimary: true
        }

        AxiosInstance.post(`/api/v1/member/${primary_member_id}/household/add`, memberHouseholdPayload).then((res => {
            if (props.id != null) {
                getHouseholdSorted().then(household => {
                    setLinkedAccounts(household);
                });
            }
        }));
    }


    const handleMakeActive = (active_member_id, isActive) => {

        const memberHouseholdPayload = {
            member_id: active_member_id,
            household_id: linkedAccounts._id,
            isActive: isActive
        }

        AxiosInstance.post(`/api/v1/member/${active_member_id}/household/add`, memberHouseholdPayload).then((res => {
            if (props.id != null) {
                getHouseholdSorted().then(household => {
                    setLinkedAccounts(household);
                });
            }
        }));
    }


    return (
        <div className="mt-3">
            <HouseholdAddExisting household_id={linkedAccounts._id}
                                  onAdded={handleHouseholdExistingMemberAdded}
                                  onClosed={()=> setShowAddExisting(!showAddExisting)}
                                  show={showAddExisting}
            />
            <Button variant="" size=""
                    onClick={()=> setShowLinkHousehold(!showLinkHousehold) }>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     className="bi bi-plus-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                    <path
                        d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                </svg>{` `}Link Account
            </Button>
            <HouseholdLink member_id={props.id}
                           onAdded={handleHouseholdExistingMemberAdded}
                           onClosed={()=> setShowLinkHousehold(!showLinkHousehold)}
                           show={showLinkHousehold}
            />
            <Row className="fw-bold">
                <Col md={4}>Primary Account Holder</Col>
            </Row>
            {
                linkedAccounts !== null ? linkedAccounts.map((linkedAccount,idx) => (
                    <Row key={idx}
                         style={{
                             backgroundColor: idx%2==0 ? "#e5e5e5": null,
                         }}

                    >
                        <Col md={4}>
                            <Dropdown className="d-inline" style={{fontStyle:"normal"}}>
                                <Dropdown.Toggle variant="link" id="dropdown-basic" as={CustomToggle}>
                                    <i className="bi bi-three-dots-vertical"></i>
                                </Dropdown.Toggle>

                                <Dropdown.Menu >
                                    <Dropdown.Item key={3}
                                                   className="text-danger"
                                                   onClick={() => handleUnlinkAccount(props.id, linkedAccount.household_id)}
                                    >
                                        Unlink
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>

                            <Link to={`/member/${linkedAccount.member_id}`}>{linkedAccount.primaryMemberName}</Link>
                        </Col>

                    </Row>
                )):null
            }
        </div>
    );
}

export default MemberLinkedAccounts;