import React, {useEffect, useState} from 'react';
import {Link, useLocation, useParams} from "react-router-dom";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import AxiosInstance from "../../AxiosInstance";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import {useNavigate} from "react-router";
import Form from "react-bootstrap/Form";
import TreeView from "../../UIComponents/TreeView";
import {Breadcrumb} from "react-bootstrap";
import API_V2 from "../../API_V2";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

const Organizations = (props) => {

    // AxiosInstance.defaults.headers['Authorization-Token'] = localStorage.getItem('userToken');

    const [organizations, setOrganizations] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        getOrganizationsV2()
    }, []);

    const getOrganizations = () => {
        // TODO: delete, was replaced with V2
        // AxiosInstance.get('/api/v1/organization').then((res => {
        //     setOrganizations(res.data);
        // }))
    }

    const getOrganizationsV2 = () => {
        // API_V2.defaults.headers['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');
        API_V2.get('/v2/organizations').then((res => {
            setOrganizations(res.data);
        }))
    }

    const handleTreeViewOnClick = (key) => {
        navigate(key, {state: {
            breadcrumb: [
                {
                    name: "Organizations",
                    link: "/organizations"
                }
            ]
            }});
    }

    return (
            <Container className="pb-5 mt-3">

                <Row className="mt-4" id="header">

                    <Col className="justify-content-center">
                        <div className="h4 d-inline">Organizations</div>
                        <Button variant="link" onClick={() => navigate('/organizations/create')}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                                 className="bi bi-plus-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                <path
                                    d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                            </svg>
                        </Button>
                    </Col>
                </Row>

                <TreeView data={organizations}
                          idColumn="organizationNumber"
                          nameColumnn="organizationName"
                          parentColumn="parentOrganization"
                          onClick={handleTreeViewOnClick}
                />


            </Container>
        )
};

export default Organizations;











