import API_V2 from "../API_V2";

class MemberDAL {

    static getMemberById(member_id) {
        return API_V2.get(`/v2/members/${member_id}`)
            .then(res => {
                // set some defaults for easier handling later
                if (res.data.phones === undefined) {
                    res.data.phones = [];
                }
                return res.data
            }
            ).catch(err => {
                if (err.response) {
                    throw {status: err.response.status, message: err.response.data.detail};
                } else if (err.request) {
                    throw {message: 'No response was received from the server.'};
                } else {
                    throw {message: err.message};
                }
            });
    }

    // Add more static methods here
}

export default MemberDAL;