import {Fragment, useEffect, useState} from "react";
import AxiosInstance from "../../AxiosInstance";
import member from "../../PageComponents/Member/Member";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";


const MemberDonationsView = (props) => {

    // campaingMembershipId: 6004deec5174e368ea9d4340
    // /api/v1/donations/6004deec5174e368ea9d4340

    /*
    [{"_id": new ObjectId("62db584b2f28163319dad508"), "campaignMembershipId": "6004de665174e368ea9d285c"}]
     */

    const [donations, setDonations] = useState([]);


    useEffect( ()=>{
        if (props.memberships) {

            props.memberships.map( (membership) => {
                AxiosInstance.get('/api/v1/donations/' + membership.campaignMembershipId).then((res => {

                    setDonations((currentState) => [...currentState, ...res.data]);
                }));
            } )
        }

    }, [props.memberships])


    // const compaignMemberships = (campaignMemberships) => {
    //
    //     campaignMemberships.map( (cm, idx) => {
    //
    //         AxiosInstance.get('/api/v1/membership/' + cm.campaignMembershipId).then((res) => {
    //             setMemberships((currentState) => [...currentState, res.data]);
    //             setMembershipsTotal( (currentState) => currentState + res.data.total)
    //         });
    //
    //     });
    //
    // }

    const formatDate = (inputDate) => {
        let d = new Date(inputDate);
        let res = d.toLocaleDateString("en-US", {
            month: "2-digit",
            day:"2-digit",
            year: "numeric"
        });
        return res.replaceAll('/','.');
    }

    return (

        <Fragment>
            <div className="h5">Donations</div>
            <Row className="fw-bold">
                <Col md={3}>Date</Col>
                <Col md={3}>External Ref</Col>
                <Col md={2}>Subscription Price</Col>
                <Col md={2}>Paid</Col>
            </Row>

            {
                donations.map((donation, idx) => (
                    <Row key={idx}>
                        <Col md={3}>
                            {
                                formatDate(donation.modifiedDt)
                            }
                            {/*toLocaleFormat('%d-%b-%Y')*/}
                        </Col>
                        <Col md={3}>
                            {donation.externalRef}
                        </Col>
                        <Col md={2}>
                            {`$ `}{donation.minAmountDue.toFixed(2)}
                        </Col>
                        <Col md={2}>
                            {`$ `}{donation.paymentsTotal.toFixed(2)}
                        </Col>

                    </Row>
                ))
            }


        </Fragment>

    );


}


export default  MemberDonationsView;