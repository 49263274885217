import {Fragment, useEffect, useState} from "react";
import DonationAmountView from "../UIComponents/Donation/DonationAmountView";
import {useLocation} from "react-router-dom";
import Button from "react-bootstrap/Button";
import AxiosInstance from "../AxiosInstance";
import {Spinner} from "react-bootstrap";
import {useNavigate} from "react-router";
import {Logger} from "../Utils";


const DonationReview = (props) => {


    const [donationData, setDonationData] = useState(null);
    const [errors, setErrors] = useState({});
    const [processing, setProcessing] = useState(false); // for disabling button while processing and showing spinner
    const [tryAgain, setTryAgain] = useState(false);
    const {state} = useLocation();
    const navigate = useNavigate();

    useEffect(()=>{

        const payload = {
            payee_member_id: localStorage.getItem("memberId"),
            cardData: {...state.cardData},
            donationAmount: state.totalAmount,
            processingFee: state.processingFee,
            campaign_id: state.campaign_id,
            campaignName: state.campaignName,
            donation_id: state.donation_id,
            donations: state.donations
        }

        setDonationData(payload);

        console.log("using effect")

        return () => {
            console.log("distroying effect")
            setDonationData(null)
        }

    }, []);


    const handleDonate = () => {

        console.log("donate payload: " + JSON.stringify(donationData));
        setProcessing(true);

        (async () => {

            try {
                const payment = await AxiosInstance.post(`/api/v1/member/${donationData.payee_member_id}/donation/pay`, donationData);
                console.log("payment payload: " + JSON.stringify(payment))

                navigate('/donate/thank-you', {state: {totalAmount: (payment.data.amount_paid / 100)}})
            } catch (err) {

                Logger("err: " + JSON.stringify(err));

                setTryAgain(true);
                if (err.response.data) {
                    const error = err.response.data;
                    if (error.error && error.message) {
                        let error_message;
                        switch(error.error.code) {
                            case 'card_declined':
                                if (error.error.message === 'CVV does not match') {
                                    error_message = "CVV does not match. Please try again.";
                                } else if (error.error.message === "EXPIRED CARD") {
                                    error_message = "Card expired. Please try another.";
                                } else if (error.error.message === "DECLINED: Over limit / Insufficient funds.") {
                                    error_message = "Card declined: over limit / Insufficient funds.";
                                } else if (error.error.message === "Postal code does not match") {
                                    error_message = "Incorrect billing zip code. Please try again.";
                                } else if (error.error.message === "Above maximum transaction count per card") {
                                    error_message = "Card Decline: Above maximum transaction count per card";
                                } else {
                                    error_message = "Card Declined. No additional reason provided by your bank.";
                                }
                                break;
                            case 'incorrect_zip':
                                error_message = "Incorrect billing zip code. Please try again.";
                                break;
                            case 'invalid_cvc':
                                error_message = "Card Declined: incorrect or invalid CVV";
                                break;
                            case 'incorrect_cvc':
                                error_message = "Card Declined: incorrect or invalid CVV";
                                break;
                            case 'processing_error':
                                error_message = "Processing Error";
                                break;
                            case 'expired_card':
                                error_message = "Expired Card";
                                break;
                            default:
                                error_message = "Card Declined";
                        }


                        Logger("error_message: " + error_message);
                        setErrors({...errors, cardError: error_message});
                    }
                }


            }

            //{"message":"402 Payment Required","error":{"code":"card_declined","message":"Above maximum transaction count per card","charge":"JRF6Z787AJD7E","declineCode":"issuer_declined"}}
            // promises.js:246

            setProcessing(false);

        })();


    }

    const handleTryAgain = () => {
        navigate('/donate', {state: {
                member_id: localStorage.getItem("memberId"),
                payee_member_id: donationData.payee_member_id,
                campaign_id: donationData.campaign_id,
                membership_id: state.membership_id,
                campaignName: donationData.campaignName
            }});
    }


    return (
        <Fragment>
            {donationData !== null ?
                <div className="m-auto col-10 col-sm-10 col-md-5 col-lg-4 col-xl-2 mt-3">

                    <DonationAmountView
                        amount={donationData.donationAmount}
                        processingFee={donationData.processingFee}
                        description={donationData.campaignName}
                    />

                    <div className="mt-5">
                        <h5>Payment Card</h5>
                        {donationData.cardData.card.brand + ` - ` + donationData.cardData.card.last4}
                    </div>

                    <div className="text-danger">
                        {errors.cardError}
                    </div>

                    <div className="d-flex justify-content-center my-5">
                        <Button
                            className="btn-yellow px-4"
                            style={{display: tryAgain ? "none": null}}
                            disabled={processing}
                            onClick={handleDonate}
                        >
                            {processing ?
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />: null
                            }
                            {processing? ` Processing` : "Donate Now"}
                        </Button>
                        <Button variant="default"
                                onClick={handleTryAgain}
                                style={{display: !tryAgain ? "none": null}}
                        >
                            Try Again
                        </Button>
                    </div>


                </div> :
                <div>No Data</div>
            }

        </Fragment>


    );

}

export default  DonationReview;

