import {useEffect, useState} from "react";
import {useNavigate} from "react-router";
import Container from "react-bootstrap/Container";
import MyCampaigns from "../../UIComponents/My/MyCampaigns";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import MyHousehold from "../../UIComponents/My/MyHousehold";
import {CardGroup} from "react-bootstrap";
import MyRecentDonations from "../../UIComponents/My/MyRecentDonations";
import MyLinkedAccounts from "../../UIComponents/My/MyLinkedAccounts";
import AxiosInstance from "../../AxiosInstance";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import member from "../Member/Member";
import {Logger} from "../../Utils";


const MyOverview = (props) => {


    const [formData, setFormData] = useState({});
    const [formErrors, setFormErrors] = useState({});
    const navigate = useNavigate();

    const [viewingMember_id, setViewingMember_id] = useState(localStorage.getItem("memberId"));
    const [yourMember_id] = useState(localStorage.getItem("memberId"));


    useEffect(() => {

        if (viewingMember_id === "undefined" || viewingMember_id === null || viewingMember_id.length < 24) {
            Logger("viewingMember_id: " + viewingMember_id);
            navigate('/');
            return;
        } else {

            AxiosInstance.get('/api/v1/member/' + viewingMember_id).then((res => {
                setFormData(res.data);
            }));
        }


        },[viewingMember_id]);

    const handleMemberSelected = (member_id) => {
        setViewingMember_id(member_id);
    }


    const welcomeMessage = (yourMember_id !== viewingMember_id?
        <Alert variant="warning" className="mt-3 mb-0">
            <h5>You are now viewing {formData.firstNm + ' ' + formData.lastNm}'s Account</h5>
            Here is {formData.firstNm}'s account summary at a glance.
            <hr/>
            <div className="d-flex justify-content-end">
                <Button variant="outline-warning"
                        className="fw-bolder"
                        onClick={()=> setViewingMember_id(yourMember_id)}

                >
                    Go Back
                </Button>
            </div>

        </Alert> :
            // default welcome message
        <div className="mt-3">
            <h5>Welcome to mFC, {formData.firstNm}</h5>
            Here is your account summary at a glance.
        </div>
    )

    return (
        <Container>
            {welcomeMessage}
            <Row className="mt-0 g-4 mb-5">
                <Col md={5}>
                    <Row>
                        <Col>
                            <MyCampaigns member_id={viewingMember_id} payee_member_id={yourMember_id}/>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <MyHousehold member_id={viewingMember_id} />
                        </Col>
                    </Row>

                </Col>
                {/*second column*/}
                <Col md={5}>
                    <Row>
                        <Col>
                            <MyRecentDonations member_id={viewingMember_id} />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            {yourMember_id == viewingMember_id?
                                <MyLinkedAccounts member_id={viewingMember_id} onSelected={handleMemberSelected} />: null
                            }

                        </Col>
                    </Row>


                </Col>
            </Row>

        </Container>
    );
}

export default MyOverview;