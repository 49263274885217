import React, {Component, Fragment} from 'react';
import './App.css';
import {Routes, Route} from 'react-router-dom';


import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap-icons/font/bootstrap-icons.css";

import AccountSummary from './PageComponents/Account/AccountSummary'
import ProcessPayment from './PageComponents/Payment/CreditCardInfo'
import Landing from './PageComponents/Welcome/Landing'
import SignIn from './PageComponents/Login/SignIn'
import SignUp from "./PageComponents/Login/SignUp";
import Header from './Layout/Header'
import Footer from './Layout/Footer'
import Person from './PageComponents/Person/Person'
import ConfirmEmail from './PageComponents/Login/ConfirmEmail'

import LinkAccount from './PageComponents/Account/LinkAccount'
import ForgotPassword from "./PageComponents/Login/ForgotPassword";
import ResetPassword from "./PageComponents/Login/ResetPassword";
import UpdateAddressPrompt from "./PageComponents/Person/UpdateAddressPrompt";
import Organization from "./PageComponents/Organization/Organization";
import Organizations from "./PageComponents/Organization/Organizations";
import Member from "./PageComponents/Member/Member";
import MemberEdit from "./PageComponents/Member/MemberEdit";
import OrganizationCreate from "./PageComponents/Organization/OrganizationCreate";
import CampaignCreate from "./PageComponents/Campaign/CampaignCreate";
import CampaignView from "./PageComponents/Campaign/CampaignView";
import CampaignRunView from "./PageComponents/Campaign/CampaignRunView";
import MemberCampaign from "./PageComponents/Member/MemberCampaign";
import MyOverview from "./PageComponents/My/MyOverview";
import Donate from "./PageComponents/Donate";
import WIP from "./PageComponents/WIP";
import DonationReview from "./PageComponents/DonationReview";
import ThankYouForPayment from "./PageComponents/Payment/ThankYouForPayment";
import MyUserProfile from "./UIComponents/My/MyUserProfile";
import IntakeForm from "./PageComponents/Form/IntakeForm";
import Http400 from "./PageComponents/Http400"
import PrivacyNotice from "./PageComponents/PrivacyNotice";
import Terms from "./PageComponents/Terms";


class App extends Component {


    render() {
        return (
            <Fragment>
                <Header/>
                        <Routes>

                            {/*Authentication*/}
                            <Route path="/sign-in" element={<SignIn />}/>
                            <Route path="/sign-up" element={<SignUp />}/>
                            <Route path="/confirm-email" element={<ConfirmEmail />} />
                            <Route path="/forgot-password" element={<ForgotPassword />} />
                            <Route path="/reset-password" element={<ResetPassword />} />
                            <Route path="/link-account" element={<LinkAccount/>}/>

                             {/*My*/}
                            <Route path="/my/overview" element={<MyOverview />}/>
                            <Route path="/my/profile" element={<MyUserProfile />} />
                            <Route path="/account-summary" element={<AccountSummary />}/>
                            <Route path="/address-update" element={<Person/>}/>
                            <Route path="/address-confirm" element={<UpdateAddressPrompt/>} />
                            <Route path="/account-process-payment" element={<ProcessPayment />}/>

                            {/*Organization*/}
                            <Route path="/organizations" element={<Organizations />} />
                            <Route path="/organizations/:organizationNumber" element={<Organization />} />
                            <Route path="/organizations/create" element={<OrganizationCreate />} />

                            {/*Campaign*/}
                            <Route path="/campaign/create" element={<CampaignCreate />} />
                            <Route path="/campaign/:campaignId" element={<CampaignView />} />
                            <Route path="/campaign/:campaignId/run/:campaignRunId" element={<CampaignRunView />} />

                            {/*Donation*/}
                            <Route path="/donate" element={<Donate />} />
                            <Route path="/donate/review" element={<DonationReview />} />
                            <Route path="/donate/thank-you" element={<ThankYouForPayment />} />


                            {/*Member*/}
                            <Route path="/member/create" element={<MemberEdit />} />
                            <Route path="/member/:memberId" element={<Member />} />
                            <Route path="/member/:member_id/campaign/:membership_id" element={<MemberCampaign />} />
                            <Route path="/member/edit/:memberId" element={<MemberEdit />} />

                            {/* temp to delete */}
                            <Route path="/wip" element={<WIP />} />
                            <Route path="/intake-form" element={<IntakeForm />}/>

                            <Route path="/terms" element={<Terms />} />
                            <Route path="/privacy-notice" element={<PrivacyNotice />} />

                            <Route path="/bad-request" element={<Http400 />} />

                            <Route path="/" exact element={<Landing />} />
                            {/*<Redirect to="/"/>*/}

                        </Routes>

                <Footer/>

            </Fragment>
        );
    }
}


export default App;


