import {Fragment} from "react";
import Container from "react-bootstrap/Container";


const PrivacyNotice = () => {


    return (
        <Container>

            <div className="col-12 col-md-8 col-lg-7 m-auto my-5 text-secondary">
                <div className="h3">Privacy Notice</div>

                <div className="pb-5">
                    <div className="h5">Effective Date: January, 1 2023</div>


                    <div className="pt-5">
                        <div className="h5">Introduction</div>
                        Welcome to MyFundCenter.com. Your privacy is critically important to us. MyFundCenter.com is
                        committed to protecting your personal information and your right to privacy. If you have any
                        questions or concerns about this privacy notice, or our practices with regards to your personal
                        information, please contact us at support@myfundcenter.com.
                    </div>

                    <div className="pt-5">
                        <div className="h5">Information We Collect</div>
                        At MyFundCenter.com, we are dedicated to safeguarding your privacy and handling your personal
                        data with care. We explicitly confirm that we do not collect nor store any credit card
                        information. To facilitate secure payment transactions, we utilize Clover.com, a PCI-compliant
                        Point of Sale (POS) vendor, renowned for its robust security measures and compliance with the
                        highest standards of payment security. The only information we collect includes necessary
                        details to enhance your experience and provide our services effectively, such as your name,
                        email address, and contact details. This collection is guided by our commitment to privacy and
                        is strictly limited to what is essential for the smooth operation of our services and
                        communication with you.
                    </div>

                    <div className="pt-5">
                        <div className="h5">How We Use Your Information</div>
                        The information we collect is used solely for the purpose of providing our services,
                        communicating with you regarding your use of our services, and enhancing your experience on our
                        site. We do not use your information for marketing purposes.
                    </div>

                    <div className="pt-5">
                        <div className="h5">Sharing Your Information</div>
                        MyFundCenter.com takes your privacy seriously. We do not share your phone number, address,
                        email, or any other personal information with any third parties. Your information is used
                        exclusively within the confines of MyFundCenter.com to serve your needs.
                    </div>

                    <div className="pt-5">
                        <div className="h5">Security of Your Information</div>
                        We prioritize the security of your personal information. We implement a variety of security
                        measures to maintain the safety of your personal information when you enter, submit, or access
                        your personal information on our site.
                    </div>

                    <div className="pt-5">
                        <div className="h5">Your Data Protection Rights</div>
                        You have specific rights under data protection law in relation to your personal information,
                        including the right to access, correct, update, or request deletion of your personal
                        information.
                        MyFundCenter.com takes these rights seriously and supports you in exercising them.
                    </div>

                    <div className="pt-5">
                        <div className="h5">Children's Privacy</div>
                        MyFundCenter.com does not knowingly collect personal information from individuals under the age
                        of 18. If we become aware that we have collected personal information from children without
                        verification of parental consent, we take steps to remove that information from our servers.
                    </div>

                    <div className="pt-5">
                        <div className="h5">Changes to This Privacy Notice</div>

                        MyFundCenter.com reserves the right to update or modify this Privacy Notice at any time and for
                        any reason. We will notify you about significant changes in the way we treat personal
                        information by sending a notice to the primary email address specified in your account, by
                        placing a prominent notice on our site, and/or by updating any privacy information on this page.
                        Your continued use of the Site and our services after such modifications will constitute your:
                        (a) acknowledgment of the modified Privacy Notice; and (b) agreement to abide and be bound by
                        that Notice.

                        We encourage you to frequently check this page for any changes to stay informed about how we are
                        protecting the personal information we collect. You acknowledge and agree that it is your
                        responsibility to review this Privacy Notice periodically and become aware of modifications.

                    </div>


                </div>
            </div>

        </Container>
    )

}

export default PrivacyNotice;