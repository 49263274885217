import axios from 'axios'
import AppConfig from "./Config/AppConfig";


const instance = axios.create({
    baseURL: AppConfig().axios.baseURL,
    timeout: 60000,
    headers: {
        'X-Custom-Header': 'foobar',
        'Authorization-Token': localStorage.getItem('userToken'),
        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    }
});


instance.interceptors.response.use((req) => {
    return req;
}, (err) => {


    if (err.response !== undefined && err.response.status === 401) { // redirect to login page if unauthorized
        window.location.replace("/")
    }

    return Promise.reject(err);
})

export default instance;