import React, {Fragment, useState} from 'react';
import Form from "react-bootstrap/Form"
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import AxiosInstance from '../../AxiosInstance.js'
import {useSearchParams} from "react-router-dom";
import {useNavigate} from "react-router";


const ResetPassword = (props) => {

    AxiosInstance.defaults.headers['callbackUrl'] = window.location.origin;



    const [formErrors, setFormErrors] = useState({});
    const [formData, setFormData] = useState({});
    const [valid, setValid] = useState(false);
    const [changed, setChanged] = useState(false);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const code = searchParams.get("code");


    const handleChangePassword = (e) => {
        e.preventDefault();
        e.stopPropagation();

        let errors = {};

        !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d@$!%*?&]{6,}$/.test(formData.password) ?
            errors.password = "Minimum 6 characters, at least one uppercase letter, " +
                "one lowercase letter and one number." :
            delete errors.password;

        formData.password !== formData.confirmPassword ? errors.confirmPassword = "Password does not match" :
            delete errors.confirmPassword;

        if (Object.keys(errors).length === 0) {
            AxiosInstance.post('/change-password', {"code": code, "password": formData.password})
                .then((res) => {
                    console.log(res)
                    setChanged(true);
                    setValid(true);
                    setFormData({}); //clear form

                    setTimeout(()=> navigate('/sign-in'), 10000);

                }).catch((err) => {
                console.log(JSON.stringify(err.response.data));
                if (err.response.data === "Not Found") {
                    errors.password = "Something went wrong. Your password hasn't been changed"
                }

                setFormErrors({...errors})
            });
        }


        setFormErrors({...errors})

    }

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setFormData({
            ...formData,
            [name]: value
        });
    };

    let resubmitLayout = <div className="container">
        <div className="row justify-content-md-center mt-5">
            <div className="col-md-5 text-center">
                <div className="h4 mb-1">Reset Password</div>
                <div className="text-muted">
                    Enter and confirm your new password below.
                </div>

            </div>
        </div>
        <div className="row justify-content-md-center mt-3">
            <Form className="col-md-5 text-center">
                <Form.Group>
                    <InputGroup className="mt-2">
                        <Form.Control name="password" required type="password"
                                      isInvalid={formErrors.password}
                                      isValid={valid}
                                      placeholder="New Password" autoComplete="disabled" onChange={handleInputChange}/>
                        <Form.Control.Feedback type="invalid">{formErrors.password}</Form.Control.Feedback>
                    </InputGroup>
                    <InputGroup className="mt-2">
                        <Form.Control name="confirmPassword" required type="password"
                                      isInvalid={formErrors.confirmPassword || formErrors.password}
                                      isValid={valid}
                                      placeholder="Confirm Password" autoComplete="disabled"
                                      onChange={handleInputChange}/>
                        <Form.Control.Feedback type="invalid">{formErrors.confirmPassword}</Form.Control.Feedback>
                    </InputGroup>
                    <InputGroup className="mt-2">
                        <Button variant="default" type="submit" disabled={changed} block onClick={handleChangePassword}>
                            Change Password
                        </Button>
                    </InputGroup>
                </Form.Group>
            </Form>
        </div>
    </div>

    let passwordChangedLayout = <div className="container">
        <div className="row justify-content-md-center mt-5">
            <div className="col-md-5 text-center">
                <div className="h4 mb-1">Reset Password</div>
                <div className="text-muted">
                    Your password has been changed. You can Sign In with your new password now.
                    You'll be redirected to a Sing In shortly.
                </div>
                <InputGroup className="mt-2">
                    <Button variant="default" type="submit" block onClick={() => navigate('/sign-in')} >
                        Sign In
                    </Button>
                </InputGroup>

            </div>
        </div>
    </div>

    return (
        changed? passwordChangedLayout: resubmitLayout
    );
}

export default (ResetPassword);